import {
	Component,
	OnInit,
	OnDestroy,
	ElementRef,
	Renderer2,
} from '@angular/core';

@Component({
	selector: 'app-atom-loader',
	templateUrl: './atom-loader.component.html',
	styleUrls: ['./atom-loader.component.less'],
})
export class AtomLoaderComponent implements OnInit, OnDestroy {
	private animationInterval: any;
	private animations: Animation[] = [];

	constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

	ngOnInit(): void {
		this.animationMorph();
		this.animationInterval = setInterval(() => this.animationMorph(), 3000);
	}

	ngOnDestroy(): void {
		clearInterval(this.animationInterval);
		this.cancelAllAnimations();
	}

	/**
	 * Cancels all active animations
	 */
	private cancelAllAnimations(): void {
		this.animations.forEach((animation) => {
			if (animation) {
				animation.cancel();
			}
		});
		this.animations = [];
	}

	/**
	 * Check if Web Animations API is supported
	 */
	private isWebAnimationsSupported(): boolean {
		return (
			'animate' in HTMLElement.prototype &&
			'Animation' in window &&
			'KeyframeEffect' in window
		);
	}

	/**
	 * Creates and runs all animations for the loader
	 */
	animationMorph() {
		// Cancel any existing animations before starting new ones
		this.cancelAllAnimations();

		if (this.isWebAnimationsSupported()) {
			this.animateWithWebAnimationsAPI();
		} else {
			this.animateWithFallback();
		}
	}

	/**
	 * Animate using Web Animations API
	 */
	private animateWithWebAnimationsAPI(): void {
		// Animate the logo rotation
		const logoElement =
			this.elementRef.nativeElement.querySelector('#logo_nove-concept');
		if (logoElement) {
			const rotateAnimation = logoElement.animate(
				[
					{ transform: 'rotate(0deg)' },
					{ transform: 'rotate(360deg)' },
				],
				{
					duration: 600,
					easing: 'linear',
					fill: 'forwards',
				}
			);

			this.animations.push(rotateAnimation);
		}

		// Animate all squares
		const totalSquares = 21;

		for (let i = 1; i <= totalSquares; i++) {
			const compactElement = this.elementRef.nativeElement.querySelector(
				`#path-${i}-compact`
			);
			const explosedElement = this.elementRef.nativeElement.querySelector(
				`#path-${i}-explosed`
			);

			if (compactElement && explosedElement) {
				const compactPath = compactElement.getAttribute('d');
				const explosedPath = explosedElement.getAttribute('d');

				// Create a sequence of animations to mimic the yoyo effect
				// First animation: compact to exploded
				const toExplodedAnimation = compactElement.animate(
					[{ d: compactPath }, { d: explosedPath }],
					{
						delay: 600,
						duration: 500,
						easing: 'ease-in-out',
						fill: 'forwards',
					}
				);

				this.animations.push(toExplodedAnimation);

				// Second animation: exploded back to compact (yoyo effect)
				toExplodedAnimation.onfinish = () => {
					const toCompactAnimation = compactElement.animate(
						[{ d: explosedPath }, { d: compactPath }],
						{
							duration: 500,
							easing: 'ease-in-out',
							fill: 'forwards',
						}
					);

					this.animations.push(toCompactAnimation);
				};
			}
		}
	}

	/**
	 * Fallback animation method using CSS transitions
	 */
	private animateWithFallback(): void {
		// Rotate the logo using CSS
		const logoElement =
			this.elementRef.nativeElement.querySelector('#logo_nove-concept');
		if (logoElement) {
			this.renderer.setStyle(
				logoElement,
				'transition',
				'transform 600ms linear'
			);
			this.renderer.setStyle(logoElement, 'transform', 'rotate(360deg)');

			// Reset rotation after animation completes
			setTimeout(() => {
				this.renderer.setStyle(logoElement, 'transition', 'none');
				this.renderer.setStyle(
					logoElement,
					'transform',
					'rotate(0deg)'
				);
			}, 600);
		}

		// Animate all squares with CSS
		const totalSquares = 21;

		for (let i = 1; i <= totalSquares; i++) {
			const compactElement = this.elementRef.nativeElement.querySelector(
				`#path-${i}-compact`
			);
			const explosedElement = this.elementRef.nativeElement.querySelector(
				`#path-${i}-explosed`
			);

			if (compactElement && explosedElement) {
				const compactPath = compactElement.getAttribute('d');
				const explosedPath = explosedElement.getAttribute('d');

				// Apply transition
				this.renderer.setStyle(
					compactElement,
					'transition',
					'all 500ms ease-in-out'
				);

				// Delay the explosion animation
				setTimeout(() => {
					// Set the path to exploded
					this.renderer.setAttribute(
						compactElement,
						'd',
						explosedPath
					);

					// Set back to compact after delay (yoyo effect)
					setTimeout(() => {
						this.renderer.setAttribute(
							compactElement,
							'd',
							compactPath
						);
					}, 500);
				}, 600);
			}
		}
	}
}
