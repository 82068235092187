import {
	Component,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges,
} from '@angular/core';
import { Color } from '@app/models/color';
import { FormationAdministrativeStatus } from '@app/models/formation';
import { FormationSession } from '@app/models/formation-session';
import { TranslateService } from '@ngx-translate/core';

export type FormationSessionType = {
	sessionId: string;
	name: string;
	status: FormationAdministrativeStatus;
	interventionsCount: number;
	start_date: number;
	end_date: number;
	color?: Color;
};

@Component({
	selector: 'app-atom-formation-session-table',
	templateUrl: './atom-formation-session-table.component.html',
	styleUrls: ['./atom-formation-session-table.component.less'],
})
export class AtomFormationSessionTableComponent implements OnInit, OnChanges {
	@Input() formationSessions: FormationSession[];
	@Input() formationId: string;

	colorFormation: string;
	formationSessionsTransformed: FormationSessionType[];
	constructor(public translateService: TranslateService) {}

	ngOnInit(): void {
		this.transformFormationSessions();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.formationSessions) this.transformFormationSessions();
	}

	transformFormationSessions() {
		if (!this.formationSessions) {
			this.formationSessionsTransformed = [];
			return;
		}

		this.formationSessionsTransformed = this.formationSessions.map(
			(session) => {
				return {
					sessionId: session.getId(),
					name: session.getLabel(),
					status: session.props.administrative_status,
					interventionsCount: session.props.interventions
						? (session.props.interventions as any[]).length
						: 0,
					start_date: session.getStartDate(),
					end_date: session.getEndDate(),
				};
			}
		);
	}
}
