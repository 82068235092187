import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AttestationPresence } from '@app/models/attestation-presence/attestation-presence';
import { AttestationPresenceService } from '@app/models/attestation-presence/attestation-presence.service';
import {
	AttestationPresenceSearchParams,
	AttestationPresenceSearchParamsInterface,
} from '@app/models/attestation-presence/attestation-presence-search-params';
import { User } from '@app/models/user/user';
import { DocumentAdministrative } from '@app/models/document-administrative';
import { FormationInterventionService } from '@app/models/formation-intervention/formation-intervention.service';
import { FormationIntervention } from '@app/models/formation-intervention/formation-intervention';

// Simplified interface for attestation presence
interface SimpleAttestationPresence {
	_id?: string;
	owner: User | string; // Only required field
	document?: any;
	status?: string;
	created_at?: Date;
	isPartial?: boolean;
	total_time?: number;
	total_time_override?: number;
	total_time_percent?: number;
}

@Component({
	selector: 'app-atom-formation-table-attestation-presence',
	templateUrl: './atom-formation-table-attestation-presence.component.html',
	styleUrls: ['./atom-formation-table-attestation-presence.component.less'],
})
export class AtomFormationTableAttestationPresenceComponent
	implements OnInit, OnChanges
{
	@Input() title: string;
	@Input() interventionId: string;
	@Input() respondants: User[] = [];
	@Input() disableUpload = true; // Disabled by default as requested
	@Input() disableGenerate: boolean;
	@Input() disableSend: boolean;
	@Input() disableDelete = true;
	@Input() generateLoading = false;
	@Input() docsToGenerate: string[] = [];
	@Input() templateOptions: string[] = []; // Available template options

	@Input() showButtonSendGrouped = false;

	@Output() emitTemplateChange = new EventEmitter<string>(); // Emit when template changes
	@Output() emitGenerate = new EventEmitter<{
		docType: string;
		template: string;
		totalTimeOverride?: number;
	}>(); // Updated to include template and totalTimeOverride
	@Output() emitUpload = new EventEmitter<File>(); // upload for a doc type
	@Output() emitSendGrouped = new EventEmitter<void>(); // send grouped for a doc type
	@Output() emitGenerateForRespondant = new EventEmitter<{
		docType: string;
		respondantId: string;
		template: string;
		totalTimeOverride?: number;
	}>(); // Updated to include template and totalTimeOverride

	@Output() emitReadDocument = new EventEmitter<string>();
	@Output() emitSendDocument = new EventEmitter<string>();
	@Output() emitDeleteDocument = new EventEmitter<string>();
	@Output() emitDownloadDocument = new EventEmitter<{
		attestationId: string;
		filename: string;
	}>();

	@ViewChild('btnsContainer') btnsContainer: ElementRef;

	// Modal properties
	showGenerateModal = false;
	showRespondantModal = false;
	selectedTemplate: string = '';
	globalTimeOverride: number = this.calculateDefaultTimeOverride();
	respondantTimeOverride: number = this.calculateDefaultTimeOverride();
	respondantModalTitle: string = '';
	respondantDefaultTimeOverride: number = this.calculateDefaultTimeOverride();
	currentRespondantId: string = '';

	private selectedFile: File;
	attestations: AttestationPresence[] = [];
	displayItems: SimpleAttestationPresence[] = [];
	loading = false;

	// Store intervention data for time calculations
	interventionData: FormationIntervention = null;

	// Map to store previous overrides for respondants
	previousOverrides: { [respondantId: string]: number } = {};

	constructor(
		public translateService: TranslateService,
		private attestationPresenceService: AttestationPresenceService,
		private interventionService: FormationInterventionService
	) {}

	ngOnInit(): void {
		// Initialize selectedTemplate if templateOptions are available
		if (this.templateOptions && this.templateOptions.length > 0) {
			this.selectedTemplate = this.templateOptions[0];
		}

		this.refresh();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (
			(changes.interventionId && !changes.interventionId.firstChange) ||
			(changes.respondants && !changes.respondants.firstChange)
		) {
			this.refresh();
		}

		// Initialize selectedTemplate when templateOptions changes
		if (
			changes.templateOptions &&
			this.templateOptions &&
			this.templateOptions.length > 0
		) {
			this.selectedTemplate = this.templateOptions[0];
		}
	}

	async refresh(): Promise<void> {
		if (!this.interventionId) {
			this.updateDisplayItems();
			return;
		}

		this.loading = true;

		// Load intervention data for time calculations
		try {
			this.interventionData = await this.interventionService.getAsAdmin(
				this.interventionId
			);
		} catch (error) {
			console.error('Error loading intervention data:', error);
		}

		const searchParams = new AttestationPresenceSearchParams();
		const searchParamsObj: AttestationPresenceSearchParamsInterface = {
			intervention: this.interventionId,
			_page: 0,
			_limit: 100,
			_populate: ['stagiaire', 'document'],
		};
		searchParams.fromObject(searchParamsObj);

		const resultItems = await this.attestationPresenceService
			.listAsAdmin(searchParams.toObject())
			.then((result) => result.items)
			.catch((error) => {
				console.error('Error loading attestations:', error);
				this.updateDisplayItems();
				this.loading = false;

				return [];
			});

		this.attestations = resultItems;

		// Store previous overrides for respondants
		this.updatePreviousOverrides();

		this.updateDisplayItems();
		this.loading = false;
	}

	updatePreviousOverrides(): void {
		// Reset previous overrides
		this.previousOverrides = {};

		// Store overrides from existing attestations
		if (this.attestations && this.attestations.length > 0) {
			this.attestations.forEach((attestation) => {
				if (
					attestation.stagiaireExists() &&
					attestation.documentExists()
				) {
					const doc = attestation.props.document;
					if (doc instanceof DocumentAdministrative) {
						if (
							doc.props.data?.formation?.intervention
								?.total_time_override
						) {
							const respondantId =
								attestation.props.stagiaire instanceof User
									? attestation.props.stagiaire.props._id
									: (attestation.props.stagiaire as string);

							this.previousOverrides[respondantId] =
								doc.props.data.formation.intervention.total_time_override;
						}
					}
				}
			});
		}
	}

	updateDisplayItems(): void {
		this.displayItems = [];

		// Add all attestations
		if (this.attestations && this.attestations.length > 0) {
			this.attestations.forEach((attestation) => {
				let document = null;
				let status = null;
				let created_at = null;
				let total_time = null;
				let total_time_override = null;
				let total_time_percent = null;

				if (attestation.documentExists()) {
					const doc = attestation.props.document;
					if (doc instanceof DocumentAdministrative) {
						document = doc;
						status = doc.props.status;
						created_at = doc.props.created_at;

						if (doc.props.data?.formation?.intervention) {
							const intervention =
								doc.props.data.formation.intervention;
							total_time = intervention.total_time || null;
							total_time_override =
								intervention.total_time_override || null;
							total_time_percent =
								intervention.total_time_percent || null;
						}
					}
				}

				const item: SimpleAttestationPresence = {
					_id: attestation.props._id,
					owner: attestation.stagiaireExists()
						? (attestation.props.stagiaire as User)
						: null,
					document,
					status,
					created_at,
					total_time,
					total_time_override,
					total_time_percent,
				};
				this.displayItems.push(item);
			});
		}

		// Add respondants that don't have attestations
		if (this.respondants && this.respondants.length > 0) {
			this.respondants.forEach((respondant) => {
				// Check if this respondant already has an attestation
				const hasAttestation = this.displayItems.some((item) => {
					if (typeof item.owner === 'string') {
						return item.owner === respondant.props._id;
					} else if (item.owner instanceof User) {
						return item.owner.props._id === respondant.props._id;
					}
					return false;
				});

				// If not, add them to the display list
				if (!hasAttestation) {
					this.displayItems.push({
						_id: 'temp_' + respondant.props._id,
						owner: respondant,
						isPartial: true,
					});
				}
			});
		}
	}

	// Helper methods for the template
	getOwnerName(item: SimpleAttestationPresence): string {
		if (!item.owner) return '-';

		if (typeof item.owner === 'string') {
			return item.owner; // Just return the ID if it's a string
		} else if (item.owner instanceof User) {
			// Access the properties safely using any to bypass type checking
			const user = item.owner as any;
			const firstName =
				user.props.firstname || user.props.first_name || '';
			const lastName = user.props.lastname || user.props.last_name || '';
			return `${firstName} ${lastName}`;
		}

		return '-';
	}

	getDocumentLabel(item: SimpleAttestationPresence): string {
		if (!item.document || item.isPartial) return '-';

		if (item.document instanceof DocumentAdministrative) {
			return item.document.props.label || '-';
		}

		return '-';
	}

	getDocumentId(item: SimpleAttestationPresence): string {
		if (!item.document || item.isPartial) return '';

		if (item.document instanceof DocumentAdministrative) {
			return item.document.props._id || '';
		}

		return '';
	}

	getDocumentStatus(item: SimpleAttestationPresence): string {
		return item.status || '';
	}

	getCreatedDate(item: SimpleAttestationPresence): Date {
		if (!item.document || !item.created_at || item.isPartial) return null;
		return item.created_at;
	}

	canDownload(item: SimpleAttestationPresence): boolean {
		return !item.isPartial && !!item.document;
	}

	// Calculate default time override based on intervention start and end dates
	// This method now uses the FormationInterventionService's calculateNormalizedTime method
	calculateDefaultTimeOverride(): number {
		return this.interventionService.calculateNormalizedTime(
			this.interventionData
		);
	}

	// Get previous override for a respondant or default if none exists
	getTimeOverrideForRespondant(respondantId: string): number {
		// If we have a previous override for this respondant, use it
		if (this.previousOverrides[respondantId]) {
			return this.previousOverrides[respondantId];
		}

		// Otherwise use the default calculation
		return this.calculateDefaultTimeOverride();
	}

	// Method to open respondant modal
	openRespondantModal(item: SimpleAttestationPresence): void {
		if (!item || !item.owner) return;

		let respondantId = '';
		if (typeof item.owner === 'string') {
			respondantId = item.owner;
		} else if (item.owner instanceof User) {
			respondantId = item.owner.props._id;
		}

		if (!respondantId) return;

		this.currentRespondantId = respondantId;
		this.respondantDefaultTimeOverride =
			this.getTimeOverrideForRespondant(respondantId);
		this.respondantTimeOverride = this.respondantDefaultTimeOverride;

		// Only set selectedTemplate if it's not already set
		if (!this.selectedTemplate && this.templateOptions.length > 0) {
			this.selectedTemplate = this.templateOptions[0];
		}

		this.respondantModalTitle = this.translateService.instant(
			'generate_attestation_for',
			{
				name: this.getOwnerName(item),
			}
		);
		this.showRespondantModal = true;
	}

	// Handle global generate submit
	handleGenerateSubmit(): void {
		if (!this.docsToGenerate || this.docsToGenerate.length === 0) return;

		const docType = this.docsToGenerate[0];
		const template =
			this.selectedTemplate ||
			(this.templateOptions.length > 0 ? this.templateOptions[0] : '');
		const totalTimeOverride =
			this.globalTimeOverride || this.calculateDefaultTimeOverride();

		this.emitGenerate.emit({
			docType,
			template,
			totalTimeOverride,
		});

		this.showGenerateModal = false;
	}

	// Handle respondant generate submit
	handleRespondantGenerateSubmit(): void {
		if (
			!this.docsToGenerate ||
			this.docsToGenerate.length === 0 ||
			!this.currentRespondantId
		)
			return;

		const docType = this.docsToGenerate[0];
		const template =
			this.selectedTemplate ||
			(this.templateOptions.length > 0 ? this.templateOptions[0] : '');
		const totalTimeOverride =
			this.respondantTimeOverride || this.respondantDefaultTimeOverride;

		this.emitGenerateForRespondant.emit({
			docType,
			respondantId: this.currentRespondantId,
			template,
			totalTimeOverride,
		});

		this.showRespondantModal = false;
	}

	onFileSelect(event) {
		this.selectedFile = event.target.files[0];
		this.emitUpload.emit(this.selectedFile);
	}
}
