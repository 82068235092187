<div
	*ngIf="!loading; else loadingTpl"
	class="container-fluid px-0 px-xl-5 py-4 py-xl-5"
>
	<div class="container-xl px-xl-0 ml-0">
		<ng-container *ngIf="formationIntervention">
			<app-atom-formation-intervention-card-detail
				*ngIf="formationIntervention"
				[formationIntervention]="formationIntervention"
				[formationTitle]="formationLabel"
			></app-atom-formation-intervention-card-detail>

			<!-- ATTESTATIONS DE PRESENCE -->
			<app-atom-formation-table-attestation-presence
				#attestationPresenceTable
				[title]="
					'formation-intervention-attestation-presence-title'
						| translate
				"
				[interventionId]="formationIntervention.props._id"
				[respondants]="respondants"
				[docsToGenerate]="docsToGenerateAttestationPresence"
				[templateOptions]="attestationTemplateOptions"
				[disableSend]="false"
				[disableDelete]="false"
				[disableGenerate]="isGenerateDisable('attestation_presence')"
				[generateLoading]="attestationPresenceLoading"
				(emitGenerate)="handleGenerate($event, 'attestation_presence')"
				(emitGenerateForRespondant)="
					handleGenerateForRespondant($event)
				"
				(emitReadDocument)="
					handleReadDocumentAttestationPresence($event)
				"
				(emitDownloadDocument)="
					handleDownloadDocumentAttestationPresence($event)
				"
				(emitSendDocument)="handleSendAttestationPresence($event)"
				(emitDeleteDocument)="
					showDeleteConfirmOnAttestationPresence($event)
				"
			></app-atom-formation-table-attestation-presence>

			<!-- DOCUMENTS CONTRAT TRAVAIL -->
			<app-atom-formation-table-documents
				[title]="'formation-intervention-contract-title' | translate"
				[docsToGenerate]="docsToGenerateContract"
				[documents]="documentContract"
				[disableSend]="isSendDisable(documentContract)"
				[disableDelete]="false"
				[disableUpload]="isUploadDisable('contrat_travail')"
				[disableGenerate]="isGenerateDisable('contrat_travail')"
				[generateLoading]="contratTravailLoading"
				(emitGenerate)="handleGenerate($event, 'contrat_travail')"
				(emitUpload)="
					handleDocAdminUpload(
						$event,
						documentContract,
						'contrat_travail'
					)
				"
				(emitReadDocument)="
					handleReadDocumentAdministrative('contrat_travail')
				"
				(emitDownloadDocument)="
					handleDownloadDocumentAdministrative(
						$event,
						'contrat_travail'
					)
				"
				(emitSendDocument)="
					handleSendAdministrative($event, 'contrat_travail')
				"
				(emitDeleteDocument)="
					showDeleteConfirmOnDocAdmin('contrat_travail')
				"
			></app-atom-formation-table-documents>

			<!-- DOCUMENTS ORDRE DE MISSION -->
			<app-atom-formation-table-documents
				[title]="
					'formation-intervention-order-mission-title' | translate
				"
				[docsToGenerate]="docsToGenerateOrderMission"
				[documents]="documentOrderMission"
				[disableSend]="isSendDisable(documentOrderMission)"
				[disableDelete]="false"
				[disableUpload]="isUploadDisable('ordre_de_mission')"
				[disableGenerate]="isGenerateDisable('ordre_de_mission')"
				[generateLoading]="ordreDeMissionLoading"
				(emitGenerate)="handleGenerate($event, 'ordre_de_mission')"
				(emitUpload)="
					handleDocAdminUpload(
						$event,
						documentOrderMission,
						'ordre_de_mission'
					)
				"
				(emitReadDocument)="
					handleReadDocumentAdministrative('ordre_de_mission')
				"
				(emitDownloadDocument)="
					handleDownloadDocumentAdministrative(
						$event,
						'ordre_de_mission'
					)
				"
				(emitSendDocument)="
					handleSendAdministrative($event, 'ordre_de_mission')
				"
				(emitDeleteDocument)="
					showDeleteConfirmOnDocAdmin('ordre_de_mission')
				"
			></app-atom-formation-table-documents>

			<!-- DOCUMENTS PEDAGOGIQUES -->
			<app-atom-formation-table-documents
				[title]="'formation-intervention-pedagogique-title' | translate"
				[documents]="documents"
				[disableSend]="false"
				[disableDelete]="false"
				[disableGenerate]="true"
				[disableUpload]="false"
				(emitUpload)="handleDocPedagogiqueUpload($event)"
				(emitReadDocument)="handleReadDocumentPedagogique($event)"
				(emitDownloadDocument)="handleDownloadDocPedagogique($event)"
				(emitSendDocument)="handleSendPedagogique($event)"
				(emitDeleteDocument)="handleDeleteDocumentPedagogique($event)"
				(emitSendGrouped)="handleSendGrouped()"
				[showButtonSendGrouped]="true"
			></app-atom-formation-table-documents>
		</ng-container>
	</div>
</div>

<ng-template #loadingTpl>
	<div class="container-fluid px-0 px-xl-5 py-4 py-xl-5">
		<div class="container-xl px-xl-0 ml-0">
			<div class="text-center">
				<i nz-icon nzType="loading"></i>
				{{ 'common_loading' | translate }}
			</div>
		</div>
	</div>
</ng-template>
