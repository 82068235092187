import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BasePageComponent } from '@app/abstracts/base-page/base-page.component';
import {
	AttestationPresence,
	AttestationPresenceService,
} from '@app/models/attestation-presence';
import { DocumentAdministrative } from '@app/models/document-administrative';
import {
	FormationIntervention,
	FormationInterventionService,
} from '@app/models/formation-intervention';

import { PageInterface, PageService } from '@app/services/page.service';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-molecule-stagiaire-attestation-presence',
	templateUrl: './molecule-stagiaire-attestation-presence.component.html',
	styleUrls: ['./molecule-stagiaire-attestation-presence.component.less'],
})
export class MoleculeStagiaireAttestationPresenceComponent
	extends BasePageComponent
	implements OnInit, OnDestroy
{
	private unsubscribe$ = new Subject<void>();

	formationIntervention: FormationIntervention;
	attestationPresence: AttestationPresence;
	document: DocumentAdministrative;
	documents: DocumentAdministrative[] = [];

	loading = false;

	constructor(
		protected pageService: PageService,
		protected translate: TranslateService,
		protected formationInterventionService: FormationInterventionService,
		protected attestationPresenceService: AttestationPresenceService,
		private route: ActivatedRoute,
		private http: HttpClient,
		private router: Router
	) {
		super(pageService, translate);
	}

	async ngOnInit() {
		super.ngOnInit();
		this.loading = true;
		try {
			await this.getFormationInterventionInfos();
			await this.getAttestationPresence();
		} catch (error) {
			console.error('Error fetching attestation presence:', error);
			this.router.navigate(['/']);
		} finally {
			this.loading = false;
		}
	}

	ngOnDestroy() {
		super.ngOnDestroy();
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	async getFormationInterventionInfos() {
		this.formationIntervention =
			await this.formationInterventionService.get(
				this.route.snapshot.params.interventionId,
				{
					_populate: ['documents', 'thematiques'],
				}
			);
	}

	getPageData(): PageInterface {
		return {
			title: this.translate.instant(
				'page_title-formation_attestation_presence',
				{
					formationIntervention: this.formationIntervention
						? this.formationIntervention.getLabel()
						: '',
				}
			),
			hideTopBar: true,
			hideMenu: true,
		};
	}

	async getAttestationPresence() {
		try {
			this.attestationPresence =
				await this.attestationPresenceService.get(
					this.route.snapshot.params.attestationId,
					{
						_populate: ['document'],
					}
				);

			if (
				this.attestationPresence &&
				this.attestationPresence.documentExists()
			) {
				this.document = this.attestationPresence.props
					.document as DocumentAdministrative;
				// Create an array with just this document for the document table component
				this.documents = [this.document];
			}
		} catch (error) {
			console.error('Error fetching attestation presence:', error);
		}
	}

	async handleClick() {
		try {
			const { url } =
				await this.formationInterventionService.getPresignedAttestationPresence(
					this.formationIntervention.getId(),
					this.attestationPresence.getId()
				);
			window.open(url, '_blank');
		} catch (error) {
			console.error('Error opening document:', error);
		}
	}

	downloadPdf(url: string, filename: string) {
		this.http
			.get(url, { responseType: 'blob' })
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe(
				(response) => {
					const file = new Blob([response], { type: response.type });

					const fileURL = URL.createObjectURL(file);
					const fakeDownload = document.createElement('a');
					fakeDownload.href = fileURL;
					fakeDownload.target = '_blank';
					fakeDownload.download = filename;
					document.body.appendChild(fakeDownload);
					fakeDownload.click();
					URL.revokeObjectURL(fileURL);
					fakeDownload.remove();
				},
				(error) => {
					console.error('Error downloading document:', error);
				}
			);
	}

	async handleDownload(event: { idDoc: string; filename: string }) {
		try {
			const { url } =
				await this.formationInterventionService.getPresignedAttestationPresenceAsAdmin(
					this.formationIntervention.getId(),
					event.idDoc
				);
			this.downloadPdf(url, event.filename);
		} catch (error) {
			console.error('Error downloading document:', error);
		}
	}

	goToIntervention() {
		// Placeholder for navigation logic if needed
	}
}
