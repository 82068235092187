import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';

import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MetaModule } from '@ngx-meta/core';
import { IsLoggedGuard } from '@app/guards/is-logged.guard';
import { IsNotLoggedGuard } from '@app/guards/is-not-logged.guard';
import { TranslateModuleLoad } from '@app/translate-import';
import { SharedModule } from '@app/shared/shared.module';
import { MoleculeSignInComponent } from '@app/molecules/connection/molecule-sign-in/molecule-sign-in.component';
import { MoleculeMyAccountComponent } from '@app/molecules/connection/molecule-my-account/molecule-my-account.component';
import { AtomSignInComponent } from '@app/atoms/connection/atom-sign-in/atom-sign-in.component';
import { MoleculeLogoutComponent } from '@app/molecules/connection/molecule-logout/molecule-logout.component';
import { AtomSignUpComponent } from '@app/atoms/connection/atom-sign-up/atom-sign-up.component';
import { MoleculeNotFoundComponent } from '@app/molecules/general/molecule-not-found/molecule-not-found.component';
import { MoleculePasswordResetComponent } from '@app/molecules/connection/molecule-password-reset/molecule-password-reset.component';
import { AtomResetPasswordComponent } from '@app/atoms/connection/atom-reset-password/atom-reset-password.component';
import { MoleculePasswordChangeComponent } from '@app/molecules/connection/molecule-password-change/molecule-password-change.component';
import { AtomChangePasswordComponent } from '@app/atoms/connection/atom-change-password/atom-change-password.component';
import { AtomQuestionComponent } from './atoms/question/atom-question/atom-question.component';
import { AtomQuestionRadioComponent } from './atoms/question/atom-question-radio/atom-question-radio.component';
import { AtomQuestionSliderComponent } from './atoms/question/atom-question-slider/atom-question-slider.component';
import { AtomGraphBarComponent } from './atoms/graph/atom-graph-bar/atom-graph-bar.component';
import { AtomGraphRadarComponent } from './atoms/graph/atom-graph-radar/atom-graph-radar.component';
import { AtomGraphGaugeComponent } from './atoms/graph/atom-graph-gauge/atom-graph-gauge.component';
import { AtomGraphGaugeSvgComponent } from './atoms/graph/atom-graph-gauge-svg/atom-graph-gauge-svg.component';
import { AtomMainMenuComponent } from './atoms/general/atom-main-menu/atom-main-menu.component';
import { AtomTopBarComponent } from './atoms/general/atom-top-bar/atom-top-bar.component';
import { MoleculeDashboardComponent } from './molecules/dashboard/molecule-dashboard/molecule-dashboard.component';
import { AtomDashboardCoachComponent } from './atoms/dashboard/atom-dashboard-coach/atom-dashboard-coach.component';
import { AtomDashboardRespondantComponent } from './atoms/dashboard/atom-dashboard-respondant/atom-dashboard-respondant.component';
import { PageService } from './services/page.service';
import { AtomSpinnerComponent } from './atoms/general/atom-spinner/atom-spinner.component';
import { AtomConceptLoaderComponent } from './atoms/general/atom-concept-loader/atom-concept-loader.component';
import { AtomLoaderComponent } from './atoms/general/atom-concept-loader/atom-loader/atom-loader.component';
import { MoleculeReportModeratedListComponent } from './molecules/report-moderated/molecule-report-list/molecule-report-moderated-list.component';
import { MoleculeRespondantListComponent } from './molecules/respondant/molecule-respondant-list/molecule-respondant-list.component';
import { MoleculeRespondantDetailsComponent } from './molecules/respondant/molecule-respondant-details/molecule-respondant-details.component';
import { MoleculeCoachDetailsComponent } from './molecules/coach/molecule-coach-details/molecule-coach-details.component';
import { MoleculeRespondantEditComponent } from './molecules/respondant/molecule-respondant-edit/molecule-respondant-edit.component';
import { MoleculeCoachListComponent } from './molecules/coach/molecule-coach-list/molecule-coach-list.component';
import { AtomIntersectionObserverComponent } from './atoms/general/atom-intersection-observer/atom-intersection-observer.component';
import { AtomModelCounterComponent } from './atoms/counter/atom-model-counter/atom-model-counter.component';
import { AtomReportModeratedCounterComponent } from './atoms/counter/atom-report-moderated-counter/atom-report-moderated-counter.component';
import { AtomRespondantCounterComponent } from './atoms/counter/atom-respondant-counter/atom-respondant-counter.component';
import { AtomModelInlineListComponent } from './atoms/inline-list/atom-model-inline-list/atom-model-inline-list.component';
import { AtomReportModeratedInlineListComponent } from './atoms/inline-list/atom-report-moderated-inline-list/atom-report-moderated-inline-list.component';
import { AtomReportModeratedCardComponent } from './atoms/cards/atom-report-moderated-card/atom-report-moderated-card.component';
import { AtomRespondantInlineListComponent } from './atoms/inline-list/atom-respondant-inline-list/atom-respondant-inline-list.component';
import { AtomRespondantCardComponent } from './atoms/cards/atom-respondant-card/atom-respondant-card.component';
import { AtomCoachCardComponent } from './atoms/cards/atom-coach-card/atom-coach-card.component';
import { MoleculeLostPasswordComponent } from '@app/molecules/connection/molecule-lost-password/molecule-lost-password.component';
import { AtomLostPasswordComponent } from '@app/atoms/connection/atom-lost-password/atom-lost-password.component';
import { ModelsModule } from '@app/models/models.module';
import { GlobalErrorService } from './services/global-error.service';
import { ErrorService } from './services/error.service';
import { SessionService } from './services/session.service';
import { EmailConfirmationService } from './services/email-confirmation.service';
import { PasswordService } from './services/password.service';
import { NavigationService } from './services/navigation.service';
import { ResizeService } from './services/resize.service';
import { EncodeHttpParamsInterceptor } from './services/http-interceptor';
import { AtomInfiniteListComponent } from './atoms/general/atom-infinite-list/atom-infinite-list.component';
import { UserWalkerService } from './models/user/user-walker.service';
import { ReportModeratedWalkerService } from './models/report-moderated/report-moderated-walker.service';
import { AtomSurveyComponent } from './atoms/survey/atom-survey/atom-survey.component';
import { AtomSurveyStartComponent } from './atoms/survey/atom-survey-start/atom-survey-start.component';
import { AtomSurveyFillComponent } from './atoms/survey/atom-survey-fill/atom-survey-fill.component';
import { AtomSurveyEndComponent } from './atoms/survey/atom-survey-end/atom-survey-end.component';
import { AtomReportModeratedSimplifiedComponent } from './atoms/report-moderated/atom-report-moderated-simplified/atom-report-moderated-simplified.component';
import { AtomSurveyDetailsComponent } from './atoms/survey/atom-survey-details/atom-survey-details.component';
import { ReportModeratedListComponent } from './atoms/report-moderated/report-moderated-list/report-moderated-list.component';
import { AtomMyAccountComponent } from './atoms/connection/atom-my-account/atom-my-account.component';
import { S3Service } from './services/s3.service';
import { AtomUploadComponent } from './atoms/media/atom-upload/atom-upload.component';
import { AtomMediaPlayerComponent } from './atoms/media/atom-media-player/atom-media-player.component';
import { AtomRespondantEditComponent } from './atoms/respondant/atom-respondant-edit/atom-respondant-edit.component';
import { AppModalService } from './services/app-modal.service';
import { AtomReportModeratedDetailledComponent } from './atoms/report-moderated/atom-report-moderated-detailled/atom-report-moderated-detailled.component';
import { AtomRespondantReportModeratedDetailledComponent } from './atoms/respondant/atom-respondant-report-moderated-detailled/atom-respondant-report-moderated-detailled.component';
import { HotkeyModule } from 'angular2-hotkeys';
import { AtomSponsorCounterComponent } from './atoms/counter/atom-sponsor-counter/atom-sponsor-counter.component';
import { AtomFormationCounterComponent } from './atoms/counter/atom-formation-counter/atom-formation-counter.component';
import { AtomThematiqueCalendarComponent } from './atoms/calendar/atom-thematique-calendar/atom-thematique-calendar.component';
import { MoleculeSponsorListComponent } from './molecules/sponsor/molecule-sponsor-list/molecule-sponsor-list.component';
import { MoleculeFormationListComponent } from './molecules/formation/molecule-formation-list/molecule-formation-list.component';
import { AtomDashboardSponsorComponent } from './atoms/dashboard/atom-dashboard-sponsor/atom-dashboard-sponsor.component';
import { IsRoleGuard } from './guards/is-role.guard';
import { AtomFormationThematiqueCardComponent } from './atoms/cards/atom-formation-thematique-card/atom-formation-thematique-card.component';
import { MoleculeFormationThematiqueListComponent } from './molecules/formation-thematique/molecule-formation-thematique-list/molecule-formation-thematique-list.component';
import { FormationThematiqueWalkerService } from './models/formation-thematique/formation-thematique-walker.service';
import { MoleculeFormationThematiqueDetailsComponent } from './molecules/formation-thematique/molecule-formation-thematique-details/molecule-formation-thematique-details.component';
import { AtomFormationThematiqueDetailsComponent } from './atoms/formation-thematique/atom-formation-thematique-details/atom-formation-thematique-details.component';
import { AtomWheelComponent } from './atoms/general/atom-wheel/atom-wheel.component';
import { AtomThematiqueFormationCounterComponent } from './atoms/counter/atom-thematique-formation-counter/atom-thematique-formation-counter.component';
import { MoleculeFormationDetailsComponent } from './molecules/formation/molecule-formation-details/molecule-formation-details.component';
import { AtomMobileCalendarComponent } from './atoms/calendar/atom-mobile-calendar/atom-mobile-calendar.component';
import { AtomQuestionOpenComponent } from './atoms/question/atom-question-open/atom-question-open.component';
import { FormationWalkerService } from './models/formation/formation-walker.service';
import { AtomFormationCardComponent } from './atoms/cards/atom-formation-card/atom-formation-card.component';
import { AtomSponsorCardComponent } from './atoms/cards/atom-sponsor-card/atom-sponsor-card.component';
import { MoleculeSponsorDetailsComponent } from './molecules/sponsor/molecule-sponsor-details/molecule-sponsor-details.component';
import { AtomSponsorFormationDetailsComponent } from './atoms/sponsor/atom-sponsor-formation-details/atom-sponsor-formation-details.component';
import { AtomSponsorDetailsComponent } from './atoms/sponsor/atom-sponsor-details/atom-sponsor-details.component';
import { AtomFormationDetailsComponent } from './atoms/formation/atom-formation-details/atom-formation-details.component';
import { AtomSkeletonComponent } from './atoms/general/atom-skeleton/atom-skeleton.component';
import { AtomSkeletonDashboardComponent } from './atoms/general/atom-skeleton/atom-skeleton-dashboard/atom-skeleton-dashboard.component';
import { AtomGraphGroupedBarComponent } from './atoms/graph/atom-graph-grouped-bar/atom-graph-grouped-bar.component';
import { MoleculeReportModeratedDetailsComponent } from './molecules/report-moderated/molecule-report-moderated-details/molecule-report-moderated-details.component';
import { AtomSkeletonDetailComponent } from './atoms/general/atom-skeleton/atom-skeleton-detail/atom-skeleton-detail.component';
import { AtomSkeletonListComponent } from './atoms/general/atom-skeleton/atom-skeleton-list/atom-skeleton-list.component';
import { AtomGraphAverageComponent } from './atoms/graph/atom-graph-average/atom-graph-average.component';
import { AtomSurveyAnimationComponent } from './atoms/survey/atom-survey-animation/atom-survey-animation.component';
import { AtomReportModeratedUserListComponent } from './atoms/report-moderated/atom-report-moderated-user-list/atom-report-moderated-user-list.component';
import { MoleculeFormationListAdminComponent } from './molecules/formation/molecule-formation-list-admin/molecule-formation-list-admin.component';
import { MoleculeFormationDetailsAdminComponent } from './molecules/formation/molecule-formation-details-admin/molecule-formation-details-admin.component';
import { MoleculeFormationSessionDetailsAdminComponent } from './molecules/formation/molecule-formation-session-details-admin/molecule-formation-session-details-admin.component';
import { AtomFormationTableComponent } from './atoms/formation/atom-formation-table/atom-formation-table.component';
import { AtomFormationInterventionTableComponent } from './atoms/formation/atom-formation-intervention-table/atom-formation-intervention-table.component';
import { AtomFormationFilterPanelComponent } from './atoms/formation/atom-formation-filter-panel/atom-formation-filter-panel.component';
import { MoleculeFormationInterventionDocument } from './molecules/formation-intervention/molecule-formation-intervention-document.component';
import { AtomPdfSignatureComponent } from './atoms/pdf/atom-pdf-signature/atom-pdf-signature.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';
import { FormationInterventionWalkerService } from './models/formation-intervention/formation-intervention-walker.service';
import { AtomFormationCardDetailComponent } from './atoms/formation/atom-formation-card/atom-formation-card-detail.component';
import { AtomFormationSessionCardDetailComponent } from './atoms/formation/atom-formation-session-card/atom-formation-session-card-detail.component';
import { AtomFormationInterventionCardDetailComponent } from './atoms/formation/atom-formation-intervention-card/atom-formation-intervention-card.component';
import { MoleculeFormationInterventionDetailsAdminComponent } from './molecules/formation/molecule-formation-intervention-details-admin/molecule-formation-intervention-details-admin.component';
import { AtomFormationTableDocumentsComponent } from './atoms/formation/atom-formation-table-documents/atom-formation-table-documents.component';
import { AtomDashboardAdminComponent } from './atoms/dashboard/atom-dashboard-admin/atom-dashboard-admin.component';
import { MoleculeStagiaireDocumentListComponent } from './molecules/stagiaire/molecule-stagiaire-document-list/molecule-stagiaire-document-list.component';
import { MoleculeStagiaireAttestationPresenceComponent } from './molecules/stagiaire/molecule-stagiaire-attestation-presence/molecule-stagiaire-attestation-presence.component';
import { AtomStagiaireDocumentTableComponent } from './atoms/stagiaire/atom-stagiaire-document-table/atom-stagiaire-document-table.component';
import { AtomGraphFormComponent } from './atoms/graph/atom-graph-form/atom-graph-form.component';
import { AtomFormationSessionTableComponent } from './atoms/formation/atom-formation-session-table/atom-formation-session-table.component';
import { AtomFormationTableAttestationPresenceComponent } from './atoms/formation/atom-formation-table-attestation-presence/atom-formation-table-attestation-presence.component';

@NgModule({
	declarations: [
		AppComponent,
		MoleculeSignInComponent,
		MoleculeLogoutComponent,
		MoleculeMyAccountComponent,
		AtomSignInComponent,
		AtomSignUpComponent,
		MoleculeNotFoundComponent,
		MoleculePasswordResetComponent,
		AtomResetPasswordComponent,
		MoleculePasswordChangeComponent,
		AtomChangePasswordComponent,
		AtomLostPasswordComponent,
		MoleculeLostPasswordComponent,
		AtomQuestionComponent,
		AtomQuestionRadioComponent,
		AtomQuestionSliderComponent,
		AtomGraphGaugeSvgComponent,
		AtomMainMenuComponent,
		AtomTopBarComponent,
		MoleculeDashboardComponent,
		AtomDashboardCoachComponent,
		AtomDashboardRespondantComponent,
		AtomSpinnerComponent,
		AtomConceptLoaderComponent,
		AtomLoaderComponent,
		MoleculeReportModeratedListComponent,
		MoleculeRespondantListComponent,
		MoleculeRespondantDetailsComponent,
		MoleculeRespondantEditComponent,
		MoleculeCoachListComponent,
		MoleculeCoachDetailsComponent,
		MoleculeFormationInterventionDocument,
		AtomPdfSignatureComponent,
		AtomModelCounterComponent,
		AtomReportModeratedCounterComponent,
		AtomRespondantCounterComponent,
		AtomModelInlineListComponent,
		AtomReportModeratedInlineListComponent,
		AtomReportModeratedCardComponent,
		AtomRespondantInlineListComponent,
		AtomRespondantCardComponent,
		AtomCoachCardComponent,
		AtomIntersectionObserverComponent,
		AtomInfiniteListComponent,
		AtomSurveyComponent,
		AtomSurveyStartComponent,
		AtomSurveyFillComponent,
		AtomSurveyEndComponent,
		AtomSurveyDetailsComponent,
		AtomReportModeratedSimplifiedComponent,
		AtomGraphBarComponent,
		AtomGraphRadarComponent,
		AtomGraphGaugeComponent,
		ReportModeratedListComponent,
		AtomMyAccountComponent,
		AtomUploadComponent,
		AtomMediaPlayerComponent,
		AtomRespondantEditComponent,
		AtomReportModeratedDetailledComponent,
		AtomRespondantReportModeratedDetailledComponent,
		AtomSponsorCounterComponent,
		AtomFormationCounterComponent,
		AtomThematiqueCalendarComponent,
		MoleculeSponsorListComponent,
		MoleculeFormationListComponent,
		MoleculeFormationListAdminComponent,
		MoleculeFormationDetailsAdminComponent,
		MoleculeFormationSessionDetailsAdminComponent,
		MoleculeFormationInterventionDetailsAdminComponent,
		AtomDashboardSponsorComponent,
		AtomDashboardAdminComponent,
		MoleculeFormationThematiqueListComponent,
		AtomFormationThematiqueCardComponent,
		MoleculeFormationThematiqueDetailsComponent,
		AtomFormationThematiqueDetailsComponent,
		AtomWheelComponent,
		AtomThematiqueFormationCounterComponent,
		MoleculeFormationDetailsComponent,
		AtomMobileCalendarComponent,
		AtomQuestionOpenComponent,
		AtomFormationCardComponent,
		AtomSponsorCardComponent,
		MoleculeSponsorDetailsComponent,
		AtomSponsorFormationDetailsComponent,
		AtomSponsorDetailsComponent,
		AtomFormationDetailsComponent,
		AtomFormationTableComponent,
		AtomFormationInterventionTableComponent,
		AtomFormationCardDetailComponent,
		AtomFormationSessionCardDetailComponent,
		AtomFormationInterventionCardDetailComponent,
		AtomFormationFilterPanelComponent,
		AtomSkeletonComponent,
		AtomSkeletonDashboardComponent,
		AtomGraphGroupedBarComponent,
		MoleculeReportModeratedDetailsComponent,
		AtomSkeletonDetailComponent,
		AtomSkeletonListComponent,
		AtomGraphAverageComponent,
		AtomSurveyAnimationComponent,
		AtomReportModeratedUserListComponent,
		AtomFormationTableDocumentsComponent,
		MoleculeStagiaireDocumentListComponent,
		MoleculeStagiaireAttestationPresenceComponent,
		AtomStagiaireDocumentTableComponent,
		AtomGraphFormComponent,
		AtomFormationSessionTableComponent,
		AtomFormationTableAttestationPresenceComponent,
	],
	imports: [
		BrowserModule.withServerTransition({ appId: 'bootstraps-pwa' }),
		AppRoutingModule,
		FormsModule,
		HttpClientModule,
		BrowserAnimationsModule,
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: environment.production,
		}),
		TranslateModuleLoad(),
		MetaModule.forRoot(),
		SharedModule.forRoot(),
		ModelsModule,
		HotkeyModule.forRoot(),
		PdfViewerModule,
		AngularSignaturePadModule,
	],
	providers: [
		IsNotLoggedGuard,
		IsLoggedGuard,
		IsRoleGuard,
		{
			provide: ErrorHandler,
			useClass: GlobalErrorService,
		},
		ErrorService,
		SessionService,
		EmailConfirmationService,
		PasswordService,
		NavigationService,
		ResizeService,
		PageService,
		UserWalkerService,
		ReportModeratedWalkerService,
		FormationThematiqueWalkerService,
		FormationWalkerService,
		FormationInterventionWalkerService,
		AppModalService,
		S3Service,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: EncodeHttpParamsInterceptor,
			multi: true,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
