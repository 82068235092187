<div class="container-xl px-xl-0 ml-0 formation-session-card">
	<div class="row mx-0">
		<div class="col-12">
			<div class="mr-0 mr-md-5 container-avatar">
				<nz-avatar
					*ngIf="sessionTransformed.avatar"
					[nzSize]="148"
					[nzSrc]="sessionTransformed.avatar"
					alt="Formation avatar"
				></nz-avatar>
				<nz-avatar
					*ngIf="!sessionTransformed.avatar"
					[nzSize]="148"
					nzIcon="user"
					alt="Default avatar"
				></nz-avatar>
			</div>
			<div class="formation-session-card__content">
				<h1 class="formation-session-card__title">
					{{ sessionTransformed.formationName }} |
					<span class="formation-session-card__dates">
						{{
							sessionTransformed.start_date
								| date
									: 'dd MMM yyyy'
									: ''
									: translateService.currentLang
						}}
						-
						{{
							sessionTransformed.end_date
								| date
									: 'dd MMM yyyy'
									: ''
									: translateService.currentLang
						}}
					</span>
				</h1>
				<p class="formation-session-card__content__infos">
					<span class="card__status">
						{{ 'table_head-state' | translate }}:
						<strong>
							{{
								'formation-state-admin-' +
									sessionTransformed.status | translate
							}}
						</strong>
					</span>
					<span class="card__date">
						{{ 'common_value-date-start' | translate }}:
						<strong>
							{{
								sessionTransformed.start_date
									| date
										: 'dd MMMM yyyy'
										: ''
										: translateService.currentLang
							}}
						</strong>
					</span>
					<span class="card__date">
						{{ 'common_value-date-end' | translate }}:
						<strong>
							{{
								sessionTransformed.end_date
									| date
										: 'dd MMMM yyyy'
										: ''
										: translateService.currentLang
							}}
						</strong>
					</span>
					<span class="card__number">
						{{ 'common_number-intervention' | translate }}:
						<strong>
							{{
								'formation-intervention-number'
									| translate
										: {
												numberIntervention:
													sessionTransformed.interventionsCount
										  }
							}}
						</strong>
					</span>
				</p>
			</div>
		</div>
	</div>
</div>
