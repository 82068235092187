import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import {
	FormationIntervention,
	FormationInterventionInterface,
	FormationInterventionPayload,
} from './formation-intervention';
import { FormationInterventionSearchParamsInterface } from './formation-intervention-search-params';
import { BaseModelService } from '@app/abstracts';
@Injectable()
export class FormationInterventionService extends BaseModelService<
	FormationIntervention,
	FormationInterventionInterface,
	FormationInterventionPayload,
	FormationInterventionSearchParamsInterface
> {
	/** @inheritDoc */
	protected path(): string {
		return 'formation-intervention';
	}
	/** @inheritDoc */
	protected new(
		object: FormationInterventionInterface
	): FormationIntervention {
		return new FormationIntervention(object);
	}

	/**
	 * Calculate normalized time based on intervention duration
	 * @param intervention The formation intervention object
	 */
	calculateNormalizedTime(intervention: FormationIntervention): number {
		if (!intervention) return 7;

		try {
			const startDate = intervention.getStartDate();
			const endDate = intervention.getEndDate();

			if (!startDate || !endDate) return 7;

			// Calculate duration in milliseconds
			const durationMs = endDate - startDate;

			// Convert to hours
			const durationHours = durationMs / (1000 * 60 * 60);

			// Round to nearest 3.5h or 7h
			if (durationHours <= 5) {
				return 3.5;
			} else {
				return 7;
			}
		} catch (error) {
			console.error('Error calculating normalized time:', error);
			return 7;
		}
	}

	/**
	 * Generate a document for a formation intervention
	 * @param docType The document type to generate
	 * @param type The type of document (ordre_de_mission or contrat_travail)
	 * @param interventionId The ID of the formation intervention
	 * @returns Promise that resolves when the generation is complete
	 */
	generate(
		docType: string,
		type: 'ordre_de_mission' | 'contrat_travail' | 'attestation_presence',
		interventionId: string
	): Promise<void> {
		const options = { withCredentials: true };
		return this.http
			.patch<void>(
				`${environment.api.uri}/admin/formation-intervention/${interventionId}/generate-${type}`,
				{ docType },
				options
			)
			.toPromise();
	}

	signDocument(
		id: string,
		docType: string,
		signature: string
	): Promise<void> {
		// Start request
		const options = {
			withCredentials: true,
		};
		return this.http
			.patch<void>(
				`${environment.api.uri}/formation-intervention/${id}/signature/${docType}`,
				{
					signature,
				},
				options
			)
			.toPromise();
	}

	getPresignedDocAdmininistrative(
		id: string,
		docType: string,
		asAdmin: boolean = false
	): Promise<{
		url: string;
	}> {
		const options = {
			withCredentials: true,
		};
		return this.http
			.get<{
				url: string;
			}>(`${this.uri(asAdmin)}/${id}/${docType}`, options)
			.toPromise();
	}

	async getPresignedDocAdmininistrativeAsAdmin(
		id: string,
		docType: string
	): Promise<{
		url: string;
	}> {
		return this.getPresignedDocAdmininistrative(id, docType, true);
	}

	getPresignedDocPedagogique(
		id: string,
		idDoc: string,
		asAdmin: boolean = false
	): Promise<{
		url: string;
	}> {
		const options = {
			withCredentials: true,
		};
		return this.http
			.get<{
				url: string;
			}>(`${this.uri(asAdmin)}/${id}/document/${idDoc}`, options)
			.toPromise();
	}

	getPresignedDocPedagogiqueAsAdmin(
		id: string,
		idDoc: string
	): Promise<{
		url: string;
	}> {
		return this.getPresignedDocPedagogique(id, idDoc, true);
	}

	uploadDocumentAdministrative(
		id: string,
		docType: string,
		uri: string
	): Promise<void> {
		// Start request
		const options = {
			withCredentials: true,
		};
		return this.http
			.patch<void>(
				`${environment.api.uri}/admin/formation-intervention/${id}/administrative/upload/${docType}`,
				{
					uri,
				},
				options
			)
			.toPromise();
	}

	uploadDocumentPedagogique(
		id: string,
		documentId: string,
		uri: string,
		label: string
	): Promise<void> {
		// Start request
		const options = {
			withCredentials: true,
		};
		return this.http
			.patch<void>(
				`${environment.api.uri}/admin/formation-intervention/${id}/pedagogique/upload/${documentId}`,
				{
					uri,
					label,
				},
				options
			)
			.toPromise();
	}

	sendEmailPedagogique(id: string, documentId?: string): Promise<void> {
		// Start request
		const options = {
			withCredentials: true,
		};

		// build path
		const path = documentId ? `/${documentId}` : '';
		return this.http
			.post<void>(
				`${environment.api.uri}/admin/formation-intervention/${id}/pedagogique/send${path}`,
				{},
				options
			)
			.toPromise();
	}

	sendEmailAdministrative(
		id: string,
		docType: 'ordre_de_mission' | 'contrat_travail'
	): Promise<void> {
		// Start request
		const options = {
			withCredentials: true,
		};

		return this.http
			.post<void>(
				`${environment.api.uri}/admin/formation-intervention/${id}/administrative/send/${docType}`,
				{},
				options
			)
			.toPromise();
	}

	sendEmailAttestationPresence(
		interventionId: string,
		attestationId: string
	): Promise<void> {
		// Start request
		const options = {
			withCredentials: true,
		};

		return this.http
			.post<void>(
				`${environment.api.uri}/admin/formation-intervention/${interventionId}/attestation-presence/${attestationId}/send`,
				{},
				options
			)
			.toPromise();
	}

	getPresignedAttestationPresence(
		interventionId: string,
		attestationId: string,
		isAdmin: boolean = false
	): Promise<{
		url: string;
	}> {
		const options = {
			withCredentials: true,
		};
		return this.http
			.get<{
				url: string;
			}>(
				`${this.uri(
					isAdmin
				)}/${interventionId}/attestation-presence/${attestationId}`,
				options
			)
			.toPromise();
	}

	getPresignedAttestationPresenceAsAdmin(
		interventionId: string,
		attestationId: string
	): Promise<{
		url: string;
	}> {
		return this.getPresignedAttestationPresence(
			interventionId,
			attestationId,
			true
		);
	}
}
