import { HttpClient } from '@angular/common/http';
import {
	AfterViewInit,
	Component,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
	BasePageComponent,
	PageSkeletonType,
} from '@app/abstracts/base-page/base-page.component';
import {
	DocumentAdministrative,
	DocumentAdministrativeService,
} from '@app/models/document-administrative';
import {
	DocumentPedagogique,
	DocumentPedagogiqueService,
} from '@app/models/document-pedagogique';
import { Formation, FormationService } from '@app/models/formation';
import { FormationSessionService } from '@app/models/formation-session';
import {
	FormationIntervention,
	FormationInterventionService,
} from '@app/models/formation-intervention';
import { ErrorService } from '@app/services/error.service';
import { User } from '@app/models/user/user';
import {
	AttestationPresence,
	AttestationPresenceService,
} from '@app/models/attestation-presence';

import { PageInterface, PageService } from '@app/services/page.service';
import { S3Service } from '@app/services/s3.service';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '@env/environment';
import { AtomFormationTableAttestationPresenceComponent } from '@app/atoms/formation/atom-formation-table-attestation-presence/atom-formation-table-attestation-presence.component';

@Component({
	selector: 'app-molecule-formation-intervention-details-admin',
	templateUrl:
		'./molecule-formation-intervention-details-admin.component.html',
	styleUrls: [
		'./molecule-formation-intervention-details-admin.component.less',
	],
})
export class MoleculeFormationInterventionDetailsAdminComponent
	extends BasePageComponent
	implements OnInit, OnDestroy, AfterViewInit
{
	@ViewChild('attestationPresenceTable')
	attestationPresenceTable: AtomFormationTableAttestationPresenceComponent;

	protected skeleton: PageSkeletonType = 'detail';
	private unsubscribe$ = new Subject<void>();

	formationIntervention: FormationIntervention;
	formationId: string;
	formationLabel: string;
	sessionId: string;
	sessionLabel: string;

	contractReadonly = false;
	orderMissionReadonly = false;

	documents: DocumentPedagogique[] = [];
	documentContract: DocumentAdministrative[] = [];
	documentOrderMission: DocumentAdministrative[] = [];
	respondants: User[] = [];

	docsToGenerateContract: string[] = [
		'CDDC-AO2-RA-Distanciel',
		'CDDC-AO2-Distanciel',
		'CDDC-AO2-RA-Presentiel',
		'CDDC-AO2-Presentiel',
		'CDD-AO2-RA-Distanciel',
		'CDD-AO2-Distanciel',
		'CDD-AO2-RA-Presentiel',
		'CDD-AO2-Presentiel',
	];
	docsToGenerateOrderMission: string[] = [
		'ordre-de-mission-presentiel',
		'ordre-de-mission-distanciel',
		'Charte de Déontologie NOVE Concept - MAJ 2024',
	];
	docsToGenerateAttestationPresence: string[] = ['attestation-presence'];

	// Available templates for attestation presence
	attestationTemplateOptions: string[] = [
		'attestation-presence-ach-mmaa',
		'attestation-presence-ama-mmaa',
		'attestation-presence-th-tsh-mmaa',
	];

	loading = false;
	contratTravailLoading = false;
	ordreDeMissionLoading = false;
	attestationPresenceLoading = false;

	constructor(
		protected pageService: PageService,
		protected translate: TranslateService,
		protected formationInterventionService: FormationInterventionService,
		protected formationSessionService: FormationSessionService,
		protected documentPedagogiqueService: DocumentPedagogiqueService,
		protected documentAdministrativeService: DocumentAdministrativeService,
		protected attestationPresenceService: AttestationPresenceService,
		protected formationService: FormationService,
		private route: ActivatedRoute,
		private s3Service: S3Service,
		private errorService: ErrorService,
		private messageConfirmation: NzMessageService,
		private http: HttpClient,
		private modal: NzModalService
	) {
		super(pageService, translate);
	}

	async ngOnInit() {
		super.ngOnInit();
		this.loading = true;
		this.formationId = this.route.snapshot.params.id;
		this.sessionId = this.route.snapshot.params.sessionId;
		await Promise.all([
			this.getFormationInterventionInfos(),
			this.getRespondants(),
		]);
		this.loading = false;
	}

	ngAfterViewInit() {
		super.ngAfterViewInit();
	}

	ngOnDestroy() {
		super.ngOnDestroy();
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	async getRespondants() {
		const formation = await this.formationService.getAsAdmin(
			this.formationId,
			{
				_populate: ['respondants'],
			}
		);
		this.respondants = formation.respondantsExists()
			? (formation.props.respondants as User[])
			: [];
	}

	async getFormationInterventionInfos() {
		this.formationIntervention =
			await this.formationInterventionService.getAsAdmin(
				this.route.snapshot.params.interventionId,
				{
					_populate: [
						'formateur',
						'thematiques',
						'formation.sponsor.avatar',
						'documents',
						'ordre_de_mission',
						'contrat_travail',
					],
				}
			);
		const formation = this.formationIntervention.props
			.formation as Formation;

		// Get session information
		const session = this.sessionId
			? await this.formationSessionService.getAsAdmin(this.sessionId, {
					_populate: ['interventions.thematiques'],
			  })
			: null;
		this.sessionLabel = session?.getLabel();

		this.documents = this.formationIntervention.props
			.documents as DocumentPedagogique[];

		this.documentContract = [
			this.formationIntervention.props
				.contrat_travail as DocumentAdministrative,
		];

		this.documentOrderMission = [
			this.formationIntervention.props
				.ordre_de_mission as DocumentAdministrative,
		];

		this.formationId = formation.getId();
		this.formationLabel = formation.getLabel();
		this.contractReadonly = this.isDocSigned(this.documentContract);
		this.orderMissionReadonly = this.isDocSigned(this.documentOrderMission);

		this.refreshPageData();
	}

	getPageData(): PageInterface {
		return {
			title: this.translate.instant(
				'page_title-formation_detail_intervention',
				{
					formationIntervention: this.formationIntervention
						? this.formationIntervention.getLabel()
						: '',
				}
			),
			breadcrumbs: [
				{
					label: this.translate.instant('top_bar-formation_list'),
					link: ['/formations/list'],
				},
				{
					label: this.formationLabel ? this.formationLabel : '',
					link: ['/formations', this.formationId, 'details'],
				},
				...(this.sessionId
					? [
							{
								label: this.sessionLabel,
								link: [
									'/formations',
									this.formationId,
									'session',
									this.sessionId,
								],
							},
					  ]
					: []),
				{
					label: this.formationIntervention
						? this.formationIntervention.getLabel()
						: null,
				},
			],
			hideTopBar: false,
			hideMenu: false,
		};
	}

	isGenerateDisable(
		docType: 'ordre_de_mission' | 'contrat_travail' | 'attestation_presence'
	): boolean {
		return false;
	}

	setGenerateLoading(
		docType:
			| 'ordre_de_mission'
			| 'contrat_travail'
			| 'attestation_presence',
		isLoading: boolean
	): void {
		if (docType === 'ordre_de_mission') {
			this.ordreDeMissionLoading = isLoading;
		} else if (docType === 'contrat_travail') {
			this.contratTravailLoading = isLoading;
		} else if (docType === 'attestation_presence') {
			this.attestationPresenceLoading = isLoading;
		}
	}

	isUploadDisable(docType: 'ordre_de_mission' | 'contrat_travail'): boolean {
		return !!this.formationIntervention.props[docType];
	}

	isSendDisable(documents: DocumentAdministrative[]): boolean {
		return this.isDocSigned(documents);
	}

	downloadPdf(url: string, filename: string) {
		this.http
			.get(url, { responseType: 'blob' })
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe((response) => {
				const file = new Blob([response], { type: response.type });

				const fileURL = URL.createObjectURL(file);
				const fakeDownload = document.createElement('a');
				fakeDownload.href = fileURL;
				fakeDownload.target = '_blank';
				fakeDownload.download = filename;
				document.body.appendChild(fakeDownload);
				fakeDownload.click();
				URL.revokeObjectURL(fileURL);
				fakeDownload.remove();
			});
	}

	async handleDownloadDocPedagogique(event: {
		idDoc: string;
		filename: string;
	}) {
		const { url } =
			await this.formationInterventionService.getPresignedDocPedagogiqueAsAdmin(
				this.formationIntervention.getId(),
				event.idDoc
			);

		this.downloadPdf(url, event.filename);
	}

	async handleDownloadDocumentAdministrative(
		event: { idDoc: string; filename: string },
		docType: string
	) {
		const { url } =
			await this.formationInterventionService.getPresignedDocAdmininistrativeAsAdmin(
				this.formationIntervention.props._id,
				docType
			);
		this.downloadPdf(url, event.filename);
	}

	async handleSendGrouped() {
		try {
			await this.formationInterventionService.sendEmailPedagogique(
				this.formationIntervention.props._id
			);
			this.createMessageConfirmationSendGrouped();
		} catch (error) {
			this.createMessageErrorSend();
			this.errorService.handle(error);
		}
	}

	async handleGenerate(
		event: {
			docType: string;
			template: string;
			totalTimeOverride?: string;
		},
		type: 'ordre_de_mission' | 'contrat_travail' | 'attestation_presence'
	): Promise<void> {
		this.setGenerateLoading(type, true);
		try {
			// Handle generation based on type
			if (type === 'attestation_presence') {
				// For attestation presence, we need to generate one for each respondant
				if (this.respondants && this.respondants.length > 0) {
					// Process respondants sequentially to avoid overwhelming the server
					await Promise.all(
						this.respondants.map((respondant) =>
							this.attestationPresenceService.generateAttestationPresence(
								this.formationIntervention.props._id,
								respondant.props._id,
								event.template,
								event.totalTimeOverride
							)
						)
					);

					await this.attestationPresenceTable.refresh();
				}
			} else {
				// Existing logic for other document types
				await this.formationInterventionService.generate(
					event.docType,
					type,
					this.formationIntervention.props._id
				);
			}

			// Refresh data after generation
			await this.getFormationInterventionInfos();

			// Show success message
			this.messageConfirmation.success(
				this.translate.instant('common_generate') +
					' ' +
					this.translate.instant('common_success')
			);
		} catch (error) {
			this.errorService.handle(error);
		} finally {
			this.setGenerateLoading(type, false);
		}
	}

	async handleDocAdminUpload(
		file: File,
		previousDoc: DocumentAdministrative[],
		type: 'ordre_de_mission' | 'contrat_travail'
	): Promise<void> {
		const hasPreviousDoc = this.docExists(previousDoc);

		try {
			const res = await this.s3Service.uploadFile(file);
			if (res) {
				// create administrative document
				const docAdded =
					await this.documentAdministrativeService.createAsAdmin({
						label: file.name,
						status: hasPreviousDoc ? 'signed' : 'created',
						uri: res,
					});

				// update formation intervention with the document adminstrative id
				await this.formationInterventionService.updateAsAdmin(
					this.formationIntervention.getId(),
					{
						[type]: docAdded.getId(),
					}
				);

				// upload administrative document to the secure bucket
				await this.formationInterventionService.uploadDocumentAdministrative(
					this.formationIntervention.props._id,
					type,
					res
				);

				// update info
				await this.getFormationInterventionInfos();
			}
		} catch (error) {
			this.errorService.handle(error);
		}
	}

	async handleDocPedagogiqueUpload(file: File) {
		try {
			const res = await this.s3Service.uploadFile(file);
			if (res) {
				// create pedagogique document
				const docAdded =
					await this.documentPedagogiqueService.createAsAdmin({
						label: file.name,
						status: 'created',
						uri: res,
					});
				const allDocs = [
					...this.documents.map((doc) => doc.getId()),
					docAdded.getId(),
				];

				// update formation intervention with all the documents id
				await this.formationInterventionService.updateAsAdmin(
					this.formationIntervention.getId(),
					{
						documents: allDocs,
					}
				);

				// upload administrative document to the secure bucket
				await this.formationInterventionService.uploadDocumentPedagogique(
					this.formationIntervention.props._id,
					docAdded.getId(),
					res,
					file.name
				);

				// update info
				await this.getFormationInterventionInfos();
			}
		} catch (error) {
			this.errorService.handle(error);
		}
	}

	async handleReadDocumentAdministrative(docType: string) {
		const { url } =
			await this.formationInterventionService.getPresignedDocAdmininistrativeAsAdmin(
				this.formationIntervention.props._id,
				docType
			);
		window.open(url, '_blank');
	}

	async handleReadDocumentPedagogique(id: string) {
		const { url } =
			await this.formationInterventionService.getPresignedDocPedagogiqueAsAdmin(
				this.formationIntervention.props._id,
				id
			);
		window.open(url, '_blank');
	}

	async handleSendPedagogique(id: string) {
		try {
			if (!id) return '';
			await this.formationInterventionService.sendEmailPedagogique(
				this.formationIntervention.props._id,
				id
			);
			this.createMessageConfirmationSend();
		} catch (error) {
			this.createMessageErrorSend();
			this.errorService.handle(error);
		}
	}

	async handleSendAdministrative(
		id: string,
		docType: 'ordre_de_mission' | 'contrat_travail'
	) {
		try {
			if (!id) return '';
			await this.formationInterventionService.sendEmailAdministrative(
				this.formationIntervention.props._id,
				docType
			);
			this.createMessageConfirmationSend();
		} catch (error) {
			this.createMessageErrorSend();
			this.errorService.handle(error);
		}
	}

	async handleDeleteDocumentPedagogique(idDocument: string) {
		const documentsWithoutDeletedId = this.documents
			.filter((doc) => doc.getId() !== idDocument)
			.map((doc) => doc.getId());

		await this.formationInterventionService.updateAsAdmin(
			this.formationIntervention.getId(),
			{
				documents: documentsWithoutDeletedId,
			}
		);
		await this.getFormationInterventionInfos();
	}

	async handleDeleteDocumentAdministrative(
		docType: 'ordre_de_mission' | 'contrat_travail'
	) {
		const payload = { [docType]: null };
		await this.formationInterventionService.updateAsAdmin(
			this.formationIntervention.getId(),
			payload
		);
		await this.getFormationInterventionInfos();
	}

	private isDocSigned(doc: DocumentAdministrative[]) {
		if (this.docExists(doc)) {
			return doc[0].props.status === 'signed';
		}
		return false;
	}

	private docExists(doc: DocumentAdministrative[]) {
		return doc.length > 0 && doc[0] !== null;
	}

	createMessageConfirmationSend(): void {
		this.messageConfirmation.success(
			this.translate.instant('common_document_send')
		);
	}

	createMessageConfirmationSendGrouped(): void {
		this.messageConfirmation.success(
			this.translate.instant('common_document_send_grouped')
		);
	}

	createMessageErrorSend(): void {
		this.messageConfirmation.success(
			this.translate.instant('common_document_send_error')
		);
	}

	showDeleteConfirmOnDocAdmin(
		docType: 'ordre_de_mission' | 'contrat_travail'
	): void {
		this.modal.confirm({
			nzTitle: this.translate.instant('document_confirm_delete'),
			nzOkText: this.translate.instant('common_yes'),
			nzOnOk: async () => {
				await this.handleDeleteDocumentAdministrative(docType);
			},
			nzCancelText: this.translate.instant('common_no'),
		});
	}

	showDeleteConfirmOnDocPedagogique(idDocument: string): void {
		this.modal.confirm({
			nzTitle: this.translate.instant('document_confirm_delete'),
			nzOkText: this.translate.instant('common_yes'),
			nzOnOk: async () => {
				await this.handleDeleteDocumentPedagogique(idDocument);
			},
			nzCancelText: this.translate.instant('common_no'),
		});
	}

	async handleReadDocumentAttestationPresence(id: string) {
		// Implement read logic for attestation presence
		console.log('Reading attestation presence document:', id);
	}

	async handleDownloadDocumentAttestationPresence(event: {
		attestationId: string;
		filename: string;
	}) {
		try {
			const { url } =
				await this.formationInterventionService.getPresignedAttestationPresenceAsAdmin(
					this.formationIntervention.props._id,
					event.attestationId
				);

			// Use the same method as for other document downloads
			this.downloadPdf(url, event.filename);
		} catch (error) {
			this.errorService.handle(error);
		}
	}

	async handleSendAttestationPresence(attestationId: string) {
		try {
			await this.formationInterventionService.sendEmailAttestationPresence(
				this.formationIntervention.getId(),
				attestationId
			);

			await this.attestationPresenceTable.refresh();

			this.createMessageConfirmationSend();
		} catch (error) {
			this.errorService.handle(error);
			this.createMessageErrorSend();
		}
	}

	showDeleteConfirmOnAttestationPresence(idDocument: string): void {
		this.modal.confirm({
			nzTitle: this.translate.instant('document_confirm_delete'),
			nzContent: this.translate.instant('document_confirm_delete'),
			nzOkText: this.translate.instant('common_yes'),
			nzOkType: 'danger',
			nzOnOk: async () => {
				try {
					// Delete the attestation presence using the service
					await this.attestationPresenceService.removeAsAdmin(
						idDocument
					);

					await this.attestationPresenceTable.refresh();

					// Show success message
					this.messageConfirmation.success(
						this.translate.instant('common_delete') +
							' ' +
							this.translate.instant('common_success')
					);
				} catch (error) {
					this.errorService.handle(error);
				}
			},
			nzCancelText: this.translate.instant('common_no'),
		});
	}

	async handleGenerateForRespondant(event: {
		docType: string;
		respondantId: string;
		template: string;
		totalTimeOverride?: string;
	}): Promise<void> {
		console.log('generate for respondant', event);
		this.setGenerateLoading('attestation_presence', true);
		try {
			// Call the service method to generate attestation presence for a specific respondant
			await this.attestationPresenceService.generateAttestationPresence(
				this.formationIntervention.props._id,
				event.respondantId,
				event.template,
				event.totalTimeOverride
			);

			await this.attestationPresenceTable.refresh();

			// Show success message
			this.messageConfirmation.success(
				this.translate.instant('common_generate') +
					' ' +
					this.translate.instant('common_success')
			);
		} catch (error) {
			this.errorService.handle(error);
		} finally {
			this.setGenerateLoading('attestation_presence', false);
		}
	}
}
