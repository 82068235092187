import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
	BasePageComponent,
	PageSkeletonType,
} from '@app/abstracts/base-page/base-page.component';
import {
	Formation,
	FormationAdministrativeStatus,
	FormationService,
	FormationAdministrativeStatusValues,
} from '@app/models/formation';
import {
	FormationSession,
	FormationSessionService,
} from '@app/models/formation-session';
import { FormationInterventionWalkerService } from '@app/models/formation-intervention/formation-intervention-walker.service';

import { PageInterface, PageService } from '@app/services/page.service';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-molecule-formation-details-admin',
	templateUrl: './molecule-formation-details-admin.component.html',
	styleUrls: ['./molecule-formation-details-admin.component.less'],
})
export class MoleculeFormationDetailsAdminComponent
	extends BasePageComponent
	implements OnInit, OnDestroy, AfterViewInit
{
	protected skeleton: PageSkeletonType = 'list';
	private unsubscribe$ = new Subject<void>();
	formation: Formation;
	formationSessions: FormationSession[] = [];
	numberSessions: number = 0;
	formationEnd: number;

	sorts = ['created_at'];
	orders = ['asc', 'desc'];
	loading = false;

	statesAdmin = FormationAdministrativeStatusValues;

	constructor(
		protected pageService: PageService,
		protected translate: TranslateService,
		public formationInterventionWalkerService: FormationInterventionWalkerService,
		public formationService: FormationService,
		public formationSessionService: FormationSessionService,
		private route: ActivatedRoute
	) {
		super(pageService, translate);
	}

	async ngOnInit() {
		super.ngOnInit();
		this.loading = true;
		this.formation = await this.formationService.getAsAdmin(
			this.route.snapshot.params.id,
			{
				_populate: [
					'sponsor.avatar',
					'sessions.interventions.thematiques',
				],
			}
		);

		this.refreshPageData();
		await this.getFormationSessions();
	}

	ngAfterViewInit() {
		super.ngAfterViewInit();
	}

	ngOnDestroy() {
		super.ngOnDestroy();
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	async getFormationSessions() {
		this.loading = true;

		if (this.formation.sessionsExists()) {
			this.formationSessions = this.formation.props
				.sessions as FormationSession[];
			this.numberSessions = this.formationSessions.length;

			// Calculate the formation end date based on the interventions in all sessions
			// This might need to be adjusted based on your data structure
			this.formationEnd = this.formation.props.end_date as number;
		} else {
			this.formationSessions = [];
			this.numberSessions = 0;
			this.formationEnd = 0;
		}

		this.loading = false;
	}

	trackById(_: number, session: FormationSession): string {
		return session.getId();
	}

	getPageData(): PageInterface {
		return {
			title: this.translate.instant('page_title-formation_list_session', {
				formation: this.formation ? this.formation.getLabel() : '',
			}),
			breadcrumbs: [
				{
					label: this.translate.instant('top_bar-formation_list'),
					link: ['/formations/list'],
				},
				{ label: this.formation ? this.formation.getLabel() : null },
			],
			hideTopBar: false,
			hideMenu: false,
		};
	}
}
