import {
	Component,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges,
} from '@angular/core';
import { Color } from '@app/models/color';
import {
	Formation,
	FormationAdministrativeStatus,
} from '@app/models/formation';
import { FormationIntervention } from '@app/models/formation-intervention';
import { User } from '@app/models/user';
import { TranslateService } from '@ngx-translate/core';

export type FormationInterventionType = {
	formationId: string;
	interventionId: string;
	name: string;
	coach: string;
	status: FormationAdministrativeStatus;
	start_date: number;
	end_date: number;
	color?: Color;
	routerLink: string[];
};

@Component({
	selector: 'app-atom-formation-intervention-table',
	templateUrl: './atom-formation-intervention-table.component.html',
	styleUrls: ['./atom-formation-intervention-table.component.less'],
})
export class AtomFormationInterventionTableComponent
	implements OnInit, OnChanges
{
	@Input() formationInterventions: FormationIntervention[];
	@Input() sessionId?: string;

	colorFormation: string;
	formationInterventionsTransformed: FormationInterventionType[];

	constructor(public translateService: TranslateService) {}

	ngOnInit(): void {
		this.transformFormationIntervention();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.formationInterventions)
			this.transformFormationIntervention();
	}

	transformFormationIntervention() {
		this.formationInterventionsTransformed =
			this.formationInterventions.map((intervention) => {
				const formationId =
					typeof intervention.props.formation === 'string'
						? intervention.props.formation
						: (intervention.props.formation as Formation)?.getId();
				return {
					formationId,
					interventionId: intervention.getId(),
					name: intervention.getLabel(),
					coach: (intervention.props.formateur as User).getLabel(),
					status: intervention.props.administrative_status,
					start_date: intervention.getStartDate(),
					end_date: intervention.getEndDate(),
					routerLink: this.sessionId
						? [
								'/formations',
								formationId,
								'session',
								this.sessionId,
								'intervention',
								intervention.getId(),
						  ]
						: [
								'/formations',
								formationId,
								'intervention',
								intervention.getId(),
						  ],
				};
			});
	}
}
