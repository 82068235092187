<div class="container-fluid">
	<header class="container-xl docs__header">
		<div class="row">
			<div class="col-12">
				<img src="./assets/svg/logo/logo_NC.svg" alt="Nove Concept" />
			</div>
		</div>
	</header>
	<div class="container-xl docs__container">
		<div class="row">
			<div class="col-12">
				<h1 class="title">
					{{ 'stagiaire-attestation-presence-title' | translate }}
				</h1>
				<ng-container *ngIf="!loading; else loadingTpl">
					<ng-container
						*ngIf="formationIntervention && attestationPresence"
					>
						<app-atom-stagiaire-document-table
							[title]="formationIntervention.getLabel()"
							[documents]="documents"
							[expiredDate]="null"
							(emitReadDocument)="handleClick()"
							(emitDownloadDocument)="handleDownload($event)"
							(emitGoToIntervention)="goToIntervention()"
						></app-atom-stagiaire-document-table>
					</ng-container>
					<ng-container *ngIf="!attestationPresence">
						<div class="alert alert-warning">
							{{
								'stagiaire-attestation-presence-not-found'
									| translate
							}}
						</div>
					</ng-container>
				</ng-container>
			</div>
		</div>
	</div>
</div>
<ng-template #loadingTpl>
	<div class="text-center my-5 py-5">
		<app-atom-concept-loader></app-atom-concept-loader>
	</div>
</ng-template>
