import { Injectable } from '@angular/core';
import {
	AttestationPresence,
	AttestationPresenceInterface,
	AttestationPresencePayload,
} from './attestation-presence';
import { AttestationPresenceSearchParamsInterface } from './attestation-presence-search-params';
import { BaseModelService } from '@app/abstracts';
import { environment } from '@env/environment';

@Injectable()
export class AttestationPresenceService extends BaseModelService<
	AttestationPresence,
	AttestationPresenceInterface,
	AttestationPresencePayload,
	AttestationPresenceSearchParamsInterface
> {
	/** @inheritDoc */
	protected path(): string {
		return 'attestation-presence';
	}
	/** @inheritDoc */
	protected new(object: AttestationPresenceInterface): AttestationPresence {
		return new AttestationPresence(object);
	}

	/**
	 * Generate attestation presence document for a specific owner
	 * @param interventionId The ID of the formation intervention
	 * @param ownerId The ID of the owner (respondant)
	 * @param template The template to use for generation
	 * @param totalTimeOverride Optional override for total time
	 * @returns Promise that resolves when the generation is complete
	 */
	generateAttestationPresence(
		interventionId: string,
		ownerId: string,
		template: string = 'attestation-presence-delegation-mmaa',
		totalTimeOverride?: string
	): Promise<void> {
		const options = { withCredentials: true };
		const payload: any = {
			template: template,
			owner: ownerId,
		};

		// Add total time override if provided
		if (totalTimeOverride) {
			payload.total_time_override = totalTimeOverride;
		}

		return this.http
			.patch<void>(
				`${environment.api.uri}/admin/formation-intervention/${interventionId}/generate-attestation-presence`,
				payload,
				options
			)
			.toPromise();
	}
}
