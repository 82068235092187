import {
	AfterViewInit,
	Component,
	OnDestroy,
	OnInit,
	ElementRef,
	Renderer2,
} from '@angular/core';

@Component({
	selector: 'app-atom-survey-animation',
	templateUrl: './atom-survey-animation.component.html',
	styleUrls: ['./atom-survey-animation.component.less'],
})
export class AtomSurveyAnimationComponent
	implements OnInit, AfterViewInit, OnDestroy
{
	// Animation references
	private animations: Animation[] = [];

	constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

	ngOnInit(): void {}

	ngAfterViewInit() {
		// Check if Web Animations API is supported
		if (this.isWebAnimationsSupported()) {
			this.animationMorph();
		} else {
			// Use fallback animation method
			this.animationMorphFallback();
			console.warn(
				'Using CSS fallback for animations as Web Animations API is not supported.'
			);
		}
	}

	/**
	 * Check if Web Animations API is supported
	 */
	private isWebAnimationsSupported(): boolean {
		return (
			'animate' in HTMLElement.prototype &&
			'Animation' in window &&
			'KeyframeEffect' in window
		);
	}

	/**
	 * Animates SVG paths from compact to exploded state using Web Animations API
	 */
	animationMorph() {
		// Total number of squares to animate
		const totalSquares = 21;

		// Common animation options
		const duration = 5000;
		const easing = 'cubic-bezier(0.16, 1, 0.3, 1)'; // Approximation of easingExponentialOut

		// Loop through all squares and set up animations
		for (let i = 1; i <= totalSquares; i++) {
			// Get the compact and exploded elements
			const compactElement = this.elementRef.nativeElement.querySelector(
				`#square-${i}-compact`
			);
			const explosedElement = this.elementRef.nativeElement.querySelector(
				`#square-${i}-explosed`
			);

			if (compactElement && explosedElement) {
				// Get the path data from the exploded element
				const explosedPath = explosedElement.getAttribute('d');

				// Create animation for the compact element
				const animation = compactElement.animate(
					[
						{ d: compactElement.getAttribute('d') }, // Start with current path
						{ d: explosedPath }, // End with exploded path
					],
					{
						duration: duration,
						easing: easing,
						fill: 'forwards',
					}
				);

				// Store animation reference
				this.animations.push(animation);

				// Create a fade-in animation for the exploded element
				const opacityAnimation = explosedElement.animate(
					[{ opacity: 0 }, { opacity: 1 }],
					{
						duration: duration,
						easing: easing,
						fill: 'forwards',
					}
				);

				// Store opacity animation reference
				this.animations.push(opacityAnimation);
			}
		}
	}

	/**
	 * Fallback animation method using CSS transitions for browsers without Web Animations API
	 */
	private animationMorphFallback() {
		const totalSquares = 21;

		// Loop through all squares
		for (let i = 1; i <= totalSquares; i++) {
			// Get the compact and exploded elements
			const compactElement = this.elementRef.nativeElement.querySelector(
				`#square-${i}-compact`
			);
			const explosedElement = this.elementRef.nativeElement.querySelector(
				`#square-${i}-explosed`
			);

			if (compactElement && explosedElement) {
				// Get the path data from the exploded element
				const explosedPath = explosedElement.getAttribute('d');

				// Apply CSS transition to compact element
				this.renderer.setStyle(
					compactElement,
					'transition',
					'all 5s cubic-bezier(0.16, 1, 0.3, 1)'
				);

				// Apply CSS transition to exploded element
				this.renderer.setStyle(
					explosedElement,
					'transition',
					'opacity 5s cubic-bezier(0.16, 1, 0.3, 1)'
				);
				this.renderer.setStyle(explosedElement, 'opacity', '1');

				// Use setTimeout to trigger the animation after a small delay
				setTimeout(() => {
					// Set the path attribute directly (this won't animate smoothly but will show the final state)
					this.renderer.setAttribute(
						compactElement,
						'd',
						explosedPath
					);
				}, 50);
			}
		}
	}

	// Clean up animations when component is destroyed
	ngOnDestroy() {
		this.animations.forEach((animation) => {
			if (animation) {
				animation.cancel();
			}
		});
		this.animations = [];
	}
}
