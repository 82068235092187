import { Helpers } from '@app/shared/helpers';
import { BaseModel, BaseModelInterface } from '@app/abstracts';
import {
	FormationIntervention,
	FormationInterventionInterface,
} from '../formation-intervention/formation-intervention';
import { User, UserInterface } from '../user/user';
import { FormationThematique } from '../formation-thematique';
export interface FormationSessionInterface extends BaseModelInterface {
	created_at: number | Date;
	interventions: (
		| string
		| FormationIntervention
		| FormationInterventionInterface
	)[];
	owner?: string | User | UserInterface;
	administrative_status: any;
}
export interface FormationSessionPayload {
	interventions: (
		| string
		| FormationIntervention
		| FormationInterventionInterface
	)[];
	owner?: string | User | UserInterface;
	administrative_status: any;
}
type FormationSessionPayloadKey = keyof FormationSessionPayload;
export class FormationSession extends BaseModel<
	FormationSessionInterface,
	FormationSessionPayload
> {
	private formatDate(date: number): string {
		var d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2) month = '0' + month;
		if (day.length < 2) day = '0' + day;

		return [day, month, year].join('/');
	}

	/** Short function to get label of instance */
	getLabel(): string {
		if (!this.interventionsExists()) {
			return this.props._id;
		}

		return (
			this.formatDate(this.getStartDate()) +
			' - ' +
			this.formatDate(this.getEndDate())
		);
	}

	getThematiques(): FormationThematique[] {
		const thematiques = [];

		if (this.interventionsExists()) {
			(this.props.interventions as FormationIntervention[]).forEach(
				(intervention) => {
					thematiques.push(...intervention.getThematiques());
				}
			);
		}

		return thematiques;
	}

	/** Denotes if the instance of interventions has been populated */
	interventionsExists(): boolean {
		return (
			!!this.props &&
			this.props.interventions instanceof Array &&
			(<FormationIntervention[]>this.props.interventions).every(
				(item) => {
					return (
						item instanceof FormationIntervention && item.exists()
					);
				}
			)
		);
	}
	/** Denotes if the instance of owner has been populated */
	ownerExists(): boolean {
		return (
			!!this.props &&
			this.props.owner instanceof User &&
			this.props.owner.exists()
		);
	}
	/** Populate the current instance from an object */
	fromObject(object: FormationSessionInterface): void {
		this.props = Object.assign({}, object);
		this.props.created_at = Helpers.convertToDate(this.props.created_at);
		if (this.props.interventions instanceof Array) {
			this.props.interventions = (<FormationInterventionInterface[]>(
				this.props.interventions
			)).map((item) => {
				return typeof item === 'object'
					? new FormationIntervention(item)
					: item;
			});
		}
		if (this.props.owner !== null && typeof this.props.owner === 'object') {
			this.props.owner = new User(<UserInterface>this.props.owner);
		}
		this.next();
	}
	/** Convert the current instance to an object */
	toObject(): FormationSessionInterface {
		const props = Object.assign({}, this.props);
		if (typeof props.owner === 'undefined') {
			props.owner = null;
		}
		props.created_at = Helpers.convertToTimestamp(props.created_at);
		if (this.props.interventions instanceof Array) {
			props.interventions = (<FormationIntervention[]>(
				props.interventions
			)).map((item) => {
				return item instanceof FormationIntervention
					? item.toObject()
					: item;
			});
		}
		if (props.owner !== null && props.owner instanceof User) {
			props.owner = props.owner.toObject();
		}
		return props;
	}
	/** Convert an instance to an object to be sent to the API */
	toPayload(): FormationSessionPayload {
		const raw = this.toObject();
		const allowed = this.allowedKeys();
		const payload = Object.keys(raw)
			.filter((key) => allowed.includes(<any>key))
			.reduce((o, k) => {
				o[k] = raw[k];
				return o;
			}, {} as FormationSessionInterface);
		payload.interventions = payload.interventions
			? payload.interventions.map((i) => this.extractId(i))
			: null;
		payload.owner = payload.owner ? this.extractId(payload.owner) : null;
		return payload as FormationSessionPayload;
	}
	/** List allowed keys to be sent to the API */
	protected allowedKeys(): FormationSessionPayloadKey[] {
		return ['interventions', 'owner', 'administrative_status'];
	}

	getStartDate(): number {
		if (!this.interventionsExists()) {
			return undefined;
		}
		const interventions = this.props
			.interventions as FormationIntervention[];
		const dates = interventions
			.map((intervention) => intervention.getStartDate())
			.filter((date) => date !== undefined);
		const min = dates.length ? Math.min(...dates) : undefined;
		return isNaN(min) ? undefined : min;
	}

	getEndDate(): number {
		if (!this.interventionsExists()) {
			return undefined;
		}
		const interventions = this.props
			.interventions as FormationIntervention[];
		const dates = interventions
			.map((intervention) => intervention.getEndDate())
			.filter((date) => date !== undefined);
		const max = dates.length ? Math.max(...dates) : undefined;

		return isNaN(max) ? undefined : max;
	}
}
