import { Component, Input, OnInit } from '@angular/core';
import {
	Formation,
	FormationAdministrativeStatus,
} from '@app/models/formation';
import { FormationSession } from '@app/models/formation-session';
import { Image } from '@app/models/image';
import { User } from '@app/models/user';
import { TranslateService } from '@ngx-translate/core';

export type FormationSessionCardType = {
	name: string;
	status: FormationAdministrativeStatus;
	start_date: number | Date;
	end_date: number | Date;
	interventionsCount: number;
	formationName: string;
	avatar?: string;
};

@Component({
	selector: 'app-atom-formation-session-card-detail',
	templateUrl: './atom-formation-session-card-detail.component.html',
	styleUrls: ['./atom-formation-session-card-detail.component.less'],
})
export class AtomFormationSessionCardDetailComponent implements OnInit {
	@Input() session: FormationSession;
	@Input() numberInterventions: number;
	@Input() formation: Formation;

	sessionTransformed: FormationSessionCardType;

	constructor(public translateService: TranslateService) {}

	ngOnInit(): void {
		this.transformSession();
	}

	transformSession() {
		if (!this.session) {
			return;
		}

		const sponsor = this.formation?.props.sponsor as User;

		// Ensure we have a valid avatar URL
		let avatarUrl = '';
		if (sponsor && sponsor.avatarExists && sponsor.avatarExists()) {
			const avatar = sponsor.props.avatar as Image;
			if (avatar && avatar.getUrl) {
				avatarUrl = avatar.getUrl();
			}
		}

		this.sessionTransformed = {
			name: this.session.getLabel(),
			status: this.session.props.administrative_status,
			start_date: this.session.getStartDate(),
			end_date: this.session.getEndDate(),
			interventionsCount: this.numberInterventions || 0,
			formationName: this.formation ? this.formation.getLabel() : '',
			avatar: avatarUrl,
		};

		// Log for debugging
		console.log('Session transformed:', this.sessionTransformed);
		console.log('Avatar URL:', avatarUrl);
		console.log('Sponsor:', sponsor);
	}
}
