import { Component, Input, OnInit } from '@angular/core';
import {
	Formation,
	FormationAdministrativeStatus,
} from '@app/models/formation';
import { Image } from '@app/models/image';
import { User } from '@app/models/user';
import { TranslateService } from '@ngx-translate/core';

export type FormationResumeCardType = {
	name: string;
	sponsor: User;
	status: FormationAdministrativeStatus;
	start_date: number | Date;
	end_date: number | Date;
	avatar?: string;
};

@Component({
	selector: 'app-atom-formation-card-detail',
	templateUrl: './atom-formation-card-detail.component.html',
	styleUrls: ['./atom-formation-card-detail.component.less'],
})
export class AtomFormationCardDetailComponent implements OnInit {
	@Input() formation: Formation;
	@Input() formationEnd: number;
	@Input() numberSessions: number;

	formationTransformed: FormationResumeCardType;

	constructor(public translateService: TranslateService) {}

	ngOnInit(): void {
		this.transformFormation();
	}

	transformFormation() {
		const sponsor = this.formation.props.sponsor as User;
		this.formationTransformed = {
			name: this.formation.getLabel(),
			sponsor: this.formation.props.sponsor as User,
			status: this.formation.props.administrative_status,
			start_date: this.formation.props.start_date,
			end_date: this.formationEnd,
			avatar: sponsor.avatarExists()
				? (sponsor.props.avatar as Image).getUrl()
				: '',
		};
	}
}
