<div class="container-fluid px-0 px-xl-5 py-4 py-xl-5">
	<div class="container-xl px-xl-0 ml-0">
		<ng-container *ngIf="formation && !loading">
			<app-atom-formation-card-detail
				[formation]="formation"
				[formationEnd]="formationEnd"
				[numberSessions]="numberSessions"
			></app-atom-formation-card-detail>
		</ng-container>
		<div
			class="d-flex flex-wrap justify-content-between align-items-center header__formations"
		>
			<h2 class="mb-0">
				{{ 'formation-session-list-admin-title' | translate }}
			</h2>
		</div>
	</div>

	<div *ngIf="!loading" class="container-xl ml-0">
		<ng-container
			*ngIf="
				formationSessions && formationSessions.length > 0;
				else emptyTpl
			"
		>
			<div class="row">
				<div class="col-12">
					<app-atom-formation-session-table
						[formationSessions]="formationSessions"
						[formationId]="formation.getId()"
					></app-atom-formation-session-table>
				</div>
			</div>
		</ng-container>
	</div>
</div>

<!-- Loading -->
<ng-template #loadingTpl>
	<div class="text-center">
		<app-atom-concept-loader></app-atom-concept-loader>
	</div>
</ng-template>
<!-- /Loading -->

<!-- Empty list -->
<ng-template #emptyTpl>
	<div class="col-12 py-4 card-container empty">
		<p>{{ 'formation-session-list-empty' | translate }}</p>
	</div>
</ng-template>
<!-- /Empty list -->
