import { BaseModel, BaseModelInterface } from '@app/abstracts';
import { User, UserInterface } from '../user/user';
import {
	FormationIntervention,
	FormationInterventionInterface,
} from '../formation-intervention/formation-intervention';
import {
	DocumentAdministrative,
	DocumentAdministrativeInterface,
} from '../document-administrative/document-administrative';
import { Helpers } from '@app/shared/helpers';
export interface AttestationPresenceInterface extends BaseModelInterface {
	created_at?: number | Date | null;
	stagiaire: string | User | UserInterface;
	intervention:
		| string
		| FormationIntervention
		| FormationInterventionInterface;
	document: string | DocumentAdministrative | DocumentAdministrativeInterface;
}
export interface AttestationPresencePayload {
	stagiaire: string | User | UserInterface;
	intervention:
		| string
		| FormationIntervention
		| FormationInterventionInterface;
	document: string | DocumentAdministrative | DocumentAdministrativeInterface;
}
type AttestationPresencePayloadKey = keyof AttestationPresencePayload;
export class AttestationPresence extends BaseModel<
	AttestationPresenceInterface,
	AttestationPresencePayload
> {
	/** Short function to get label of instance */
	getLabel(): string {
		return this.props._id;
	}
	/** Denotes if the instance of stagiaire has been populated */
	stagiaireExists(): boolean {
		return (
			!!this.props &&
			this.props.stagiaire instanceof User &&
			this.props.stagiaire.exists()
		);
	}
	/** Denotes if the instance of intervention has been populated */
	interventionExists(): boolean {
		return (
			!!this.props &&
			this.props.intervention instanceof FormationIntervention &&
			this.props.intervention.exists()
		);
	}
	/** Denotes if the instance of document has been populated */
	documentExists(): boolean {
		return (
			!!this.props &&
			this.props.document instanceof DocumentAdministrative &&
			this.props.document.exists()
		);
	}
	/** Populate the current instance from an object */
	fromObject(object: AttestationPresenceInterface): void {
		this.props = Object.assign({}, object);
		this.props.created_at = Helpers.convertToDate(this.props.created_at);
		if (typeof this.props.stagiaire === 'object') {
			this.props.stagiaire = new User(
				<UserInterface>this.props.stagiaire
			);
		}
		if (typeof this.props.intervention === 'object') {
			this.props.intervention = new FormationIntervention(
				<FormationInterventionInterface>this.props.intervention
			);
		}
		if (typeof this.props.document === 'object') {
			this.props.document = new DocumentAdministrative(
				<DocumentAdministrativeInterface>this.props.document
			);
		}
		this.next();
	}
	/** Convert the current instance to an object */
	toObject(): AttestationPresenceInterface {
		const props = Object.assign({}, this.props);
		props.created_at = Helpers.convertToTimestamp(props.created_at);
		if (props.stagiaire instanceof User) {
			props.stagiaire = props.stagiaire.toObject();
		}
		if (props.intervention instanceof FormationIntervention) {
			props.intervention = props.intervention.toObject();
		}
		if (props.document instanceof DocumentAdministrative) {
			props.document = props.document.toObject();
		}
		return props;
	}
	/** Convert an instance to an object to be sent to the API */
	toPayload(): AttestationPresencePayload {
		const raw = this.toObject();
		const allowed = this.allowedKeys();
		const payload = Object.keys(raw)
			.filter((key) => allowed.includes(<any>key))
			.reduce((o, k) => {
				o[k] = raw[k];
				return o;
			}, {} as AttestationPresenceInterface);
		payload.stagiaire = payload.stagiaire
			? this.extractId(payload.stagiaire)
			: null;
		payload.intervention = payload.intervention
			? this.extractId(payload.intervention)
			: null;
		payload.document = payload.document
			? this.extractId(payload.document)
			: null;
		return payload as AttestationPresencePayload;
	}
	/** List allowed keys to be sent to the API */
	protected allowedKeys(): AttestationPresencePayloadKey[] {
		return ['stagiaire', 'intervention', 'document'];
	}
}
