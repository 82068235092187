<div class="table__container">
	<div class="table__header">
		<h2 class="table__title">
			{{ title }}
		</h2>
		<div class="d-flex btns-container" #btnsContainer>
			<button
				*ngIf="
					!showButtonSendGrouped &&
					docsToGenerate &&
					docsToGenerate.length > 0
				"
				class="btn--generate"
				nz-button
				nzType="primary"
				[disabled]="generateLoading || disableGenerate"
				(click)="showGenerateModal = true"
			>
				{{ 'common_generate' | translate }}
				<ng-container *ngIf="generateLoading">
					<i class="ml-2" nz-icon [nzType]="'loading'"></i>
				</ng-container>
			</button>

			<button
				*ngIf="showButtonSendGrouped"
				nz-button
				nzType="default"
				class="icon-btn"
				[title]="'common_grouped_send' | translate"
				(click)="emitSendGrouped.emit()"
			>
				<i nz-icon nzType="mail" nzTheme="outline"></i>
			</button>
		</div>
	</div>
	<div class="table__wrapper">
		<table>
			<thead>
				<tr>
					<th>{{ 'table_head-document-respondant' | translate }}</th>
					<th>{{ 'table_head-document-state' | translate }}</th>
					<th>{{ 'table_head-document-date' | translate }}</th>
					<th>{{ 'table_head-document-total-time' | translate }}</th>
					<th>
						{{
							'table_head-document-total-time-override'
								| translate
						}}
					</th>
					<th>{{ 'table_head-document-actions' | translate }}</th>
				</tr>
			</thead>
			<tbody>
				<ng-container *ngIf="loading">
					<tr>
						<td colspan="7" class="text-center">
							<i nz-icon nzType="loading"></i>
							{{ 'common_loading' | translate }}
						</td>
					</tr>
				</ng-container>
				<ng-container
					*ngIf="
						!loading && displayItems.length > 0;
						else noAttestationsTpl
					"
				>
					<tr *ngFor="let item of displayItems; let i = index">
						<!-- Owner -->
						<td class="owner">
							{{ getOwnerName(item) }}
						</td>

						<!-- State -->
						<td
							class="state"
							*ngIf="!item.isPartial && item.document"
							[ngSwitch]="getDocumentStatus(item)"
						>
							<ng-container *ngSwitchCase="'created'">
								<span>{{
									'formation-intervention-state-admin-created'
										| translate
								}}</span>
							</ng-container>
							<ng-container *ngSwitchCase="'generated'">
								<span>{{
									'formation-intervention-state-admin-generated'
										| translate
								}}</span>
							</ng-container>
							<ng-container *ngSwitchCase="'sent'">
								<span>{{
									'formation-intervention-state-admin-sended'
										| translate
								}}</span>
							</ng-container>
							<ng-container *ngSwitchCase="'signed'">
								<span
									class="d-flex align-items-center justify-content-center"
								>
									<i
										nz-icon
										nzType="check-circle"
										class="mr-2"
										[style.color]="'black'"
									></i>
									<span>{{
										'formation-intervention-state-admin-signed'
											| translate
									}}</span>
								</span>
							</ng-container>
							<ng-container *ngSwitchDefault>
								<span>-</span>
							</ng-container>
						</td>
						<td
							class="state"
							*ngIf="item.isPartial || !item.document"
						>
							<span>-</span>
						</td>

						<!-- Date -->
						<td class="date">
							<span
								*ngIf="
									!item.isPartial &&
									item.document &&
									getCreatedDate(item)
								"
							>
								{{
									getCreatedDate(item)
										| date
											: 'dd MMMM yyyy'
											: ''
											: translateService.currentLang
								}}
							</span>
							<span
								*ngIf="
									item.isPartial ||
									!item.document ||
									!getCreatedDate(item)
								"
								>-</span
							>
						</td>

						<!-- Total Time -->
						<td class="total-time">
							<span *ngIf="item.total_time">
								{{ item.total_time }}
							</span>
							<span *ngIf="!item.total_time">-</span>
						</td>

						<!-- Total Time Override -->
						<td class="total-time-override">
							<span *ngIf="item.total_time_override">
								{{ item.total_time_override }} ({{
									item.total_time_percent
								}}%)
							</span>
							<span
								*ngIf="
									!item.total_time_override && item.total_time
								"
								>{{ item.total_time }} (100%)</span
							>
							<span
								*ngIf="
									!item.total_time_override &&
									!item.total_time
								"
								>-</span
							>
						</td>

						<!-- Actions -->
						<td>
							<div class="actions">
								<!-- Generate button for specific respondant -->
								<button
									*ngIf="
										docsToGenerate &&
										docsToGenerate.length > 0
									"
									nz-button
									nzType="default"
									class="icon-btn"
									[title]="'common_generate' | translate"
									[disabled]="
										generateLoading || disableGenerate
									"
									(click)="openRespondantModal(item)"
								>
									<i
										nz-icon
										nzType="file-add"
										nzTheme="outline"
									></i>
								</button>

								<!-- Download button -->
								<button
									nz-button
									nzType="default"
									class="icon-btn"
									[title]="'common_download' | translate"
									[disabled]="!canDownload(item)"
									(click)="
										canDownload(item) &&
											emitDownloadDocument.emit({
												attestationId: item._id,
												filename: getDocumentLabel(item)
											})
									"
								>
									<i
										nz-icon
										nzType="download"
										nzTheme="outline"
									></i>
								</button>

								<!-- Send button -->
								<button
									nz-button
									nzType="default"
									class="icon-btn"
									[title]="'common_send' | translate"
									[disabled]="
										disableSend || !canDownload(item)
									"
									(click)="
										canDownload(item) &&
											emitSendDocument.emit(item._id)
									"
								>
									<i
										nz-icon
										nzType="mail"
										nzTheme="outline"
									></i>
								</button>

								<!-- Delete button -->
								<button
									nz-button
									nzType="default"
									class="icon-btn delete"
									[title]="'common_delete' | translate"
									[disabled]="
										disableDelete || !canDownload(item)
									"
									(click)="
										canDownload(item) &&
											emitDeleteDocument.emit(item._id)
									"
								>
									<i
										nz-icon
										nzType="delete"
										nzTheme="outline"
									></i>
								</button>
							</div>
						</td>
					</tr>
				</ng-container>
				<ng-template #noAttestationsTpl>
					<tr>
						<td colspan="7">
							{{ 'empty-attestations' | translate }}
						</td>
					</tr>
				</ng-template>
			</tbody>
		</table>
	</div>
</div>

<!-- Global Generate Modal -->
<nz-modal
	[(nzVisible)]="showGenerateModal"
	[nzTitle]="'generate_attestation' | translate"
	(nzOnCancel)="showGenerateModal = false"
	(nzOnOk)="handleGenerateSubmit()"
>
	<ng-container>
		<div class="generate-popup">
			<div class="form-group">
				<label>{{ 'select_template' | translate }}</label>
				<nz-select
					class="w-100"
					[(ngModel)]="selectedTemplate"
					nzAllowClear="false"
				>
					<nz-option
						*ngFor="let template of templateOptions"
						[nzValue]="template"
						[nzLabel]="template"
					></nz-option>
				</nz-select>
			</div>
			<div class="form-group">
				<label
					>{{ 'total_time_override' | translate }}
					{{ globalTimeOverride }}</label
				>
				<input
					nz-input
					type="number"
					[(ngModel)]="globalTimeOverride"
					[placeholder]="calculateDefaultTimeOverride()"
				/>
			</div>
		</div>
	</ng-container>
</nz-modal>

<!-- Respondant Generate Modal -->
<nz-modal
	[(nzVisible)]="showRespondantModal"
	[nzTitle]="respondantModalTitle"
	(nzOnCancel)="showRespondantModal = false"
	(nzOnOk)="handleRespondantGenerateSubmit()"
>
	<ng-container>
		<div class="generate-popup">
			<div class="form-group">
				<label>{{ 'select_template' | translate }}</label>
				<nz-select
					class="w-100"
					[(ngModel)]="selectedTemplate"
					nzAllowClear="false"
				>
					<nz-option
						*ngFor="let template of templateOptions"
						[nzValue]="template"
						[nzLabel]="template"
					></nz-option>
				</nz-select>
			</div>
			<div class="form-group">
				<label>{{ 'total_time_override' | translate }}</label>
				<input
					nz-input
					type="number"
					[(ngModel)]="respondantTimeOverride"
					[placeholder]="respondantDefaultTimeOverride"
				/>
			</div>
		</div>
	</ng-container>
</nz-modal>
