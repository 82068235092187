import { Component, OnInit, Input } from '@angular/core';
import { User, UserService } from '@app/models/user';
import { FormationService } from '@app/models/formation';
import {
	FormationIntervention,
	FormationInterventionSearchParams,
	FormationInterventionService,
} from '@app/models/formation-intervention';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
	selector: 'app-atom-dashboard-admin',
	templateUrl: './atom-dashboard-admin.component.html',
	styleUrls: ['./atom-dashboard-admin.component.less'],
})
export class AtomDashboardAdminComponent implements OnInit {
	@Input() user: User;
	protected unsubscribe = new Subject<void>();

	loading = false;
	numberFormationInterventionTodo: number;
	numberCoach: number;
	numberFormation: number;
	formationInterventions: FormationIntervention[];
	searchParams: FormationInterventionSearchParams;

	constructor(
		private formationService: FormationService,
		private formationInterventionService: FormationInterventionService,
		private userService: UserService
	) {}

	async ngOnInit(): Promise<void> {
		this.searchParams = new FormationInterventionSearchParams({
			_page: 0,
			_limit: 100,
			_sort: 'created_at',
			_order: 'asc',
			administrative_status: 'todo',
			_populate: ['thematiques', 'formateur', 'admin'],
		});

		this.searchParams
			.pipe(takeUntil(this.unsubscribe))
			.subscribe(async () => {
				await this.getInterventions();
			});

		this.loading = true;
		await this.getFormationInterventions();
		await this.getCoachs();
		await this.getFormations();
		this.loading = false;
	}

	ngOnDestroy() {
		this.unsubscribe.next();
	}

	async getFormationInterventions() {
		this.numberFormationInterventionTodo =
			await this.formationInterventionService.countAsAdmin({
				_page: 0,
				_limit: 100,
				administrative_status: ['todo'],
			});
	}

	async getCoachs() {
		this.numberCoach = await this.userService.countAsAdmin({
			_page: 0,
			_limit: 100,
			role: 'admin',
		});
	}

	async getFormations() {
		this.numberFormation = await this.formationService.countAsAdmin({
			_page: 0,
			_limit: 100,
		});
	}

	async getInterventions() {
		this.formationInterventions = await this.formationInterventionService
			.listAsAdmin(this.searchParams.toObject())
			.then((result) => result.items);
		console.log('t1', this.formationInterventions);
	}
}
