import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
	BasePageComponent,
	PageSkeletonType,
} from '@app/abstracts/base-page/base-page.component';
import {
	Formation,
	FormationAdministrativeStatus,
	FormationService,
	FormationAdministrativeStatusValues,
} from '@app/models/formation';
import {
	FormationSession,
	FormationSessionService,
} from '@app/models/formation-session';
import {
	FormationIntervention,
	FormationInterventionSearchParams,
	FormationInterventionService,
} from '@app/models/formation-intervention';
import { FormationInterventionWalkerService } from '@app/models/formation-intervention/formation-intervention-walker.service';

import { PageInterface, PageService } from '@app/services/page.service';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-molecule-formation-session-details-admin',
	templateUrl: './molecule-formation-session-details-admin.component.html',
	styleUrls: ['./molecule-formation-session-details-admin.component.less'],
})
export class MoleculeFormationSessionDetailsAdminComponent
	extends BasePageComponent
	implements OnInit, OnDestroy, AfterViewInit
{
	protected skeleton: PageSkeletonType = 'list';
	private unsubscribe$ = new Subject<void>();
	searchParams: FormationInterventionSearchParams;
	formation: Formation;
	formationSession: FormationSession;
	formationInterventions: FormationIntervention[];
	numberIntervention: number;
	formationId: string;
	sessionId: string;

	sorts = ['created_at', 'label'];
	orders = ['asc', 'desc'];
	loading = false;

	statesAdmin = FormationAdministrativeStatusValues;

	constructor(
		protected pageService: PageService,
		protected translate: TranslateService,
		public formationInterventionWalkerService: FormationInterventionWalkerService,
		public formationService: FormationService,
		public formationSessionService: FormationSessionService,
		public formationInterventionService: FormationInterventionService,
		private route: ActivatedRoute
	) {
		super(pageService, translate);
	}

	async ngOnInit() {
		super.ngOnInit();
		this.loading = true;
		this.formationId = this.route.snapshot.params.id;
		this.sessionId = this.route.snapshot.params.sessionId;

		try {
			// Get formation data with sponsor and avatar
			this.formationService
				.getAsAdmin(this.formationId, {
					_populate: ['sponsor.avatar'],
				})
				.then((formation) => {
					this.formation = formation;
				});

			// Get session data
			this.formationSessionService
				.getAsAdmin(this.sessionId, {
					_populate: [
						'interventions.thematiques',
						'interventions.formateur',
					],
				})
				.then((formationSession) => {
					this.formationSession = formationSession;
					this.getFormationInterventions();
				});

			this.refreshPageData();
		} catch (error) {
			console.error('Error loading data:', error);
		} finally {
			this.loading = false;
		}
	}

	ngAfterViewInit() {
		super.ngAfterViewInit();
	}

	ngOnDestroy() {
		super.ngOnDestroy();
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	getFormationInterventions() {
		this.loading = true;

		this.formationInterventions = this.formationSession.props
			.interventions as FormationIntervention[];
		this.numberIntervention = this.formationInterventions.length;

		this.loading = false;
	}

	trackById(_: number, intervention: FormationIntervention): string {
		return intervention.getId();
	}

	getPageData(): PageInterface {
		return {
			title: this.translate.instant(
				'page_title-formation_session_interventions',
				{
					formation: this.formation ? this.formation.getLabel() : '',
					session: this.formationSession
						? this.formationSession.getLabel()
						: '',
				}
			),
			breadcrumbs: [
				{
					label: this.translate.instant('top_bar-formation_list'),
					link: ['/formations/list'],
				},
				{
					label: this.formation ? this.formation.getLabel() : null,
					link: ['/formations', this.formationId, 'details'],
				},
				{
					label: this.formationSession?.getLabel(),
				},
			],
			hideTopBar: false,
			hideMenu: false,
		};
	}

	getLastDateFromFormationInterventions(
		formationInterventions: FormationIntervention[]
	) {
		if (!formationInterventions.length) {
			return 0;
		}
		return Math.max(
			...formationInterventions.map((formationIntervention) =>
				formationIntervention.getEndDate()
			)
		);
	}

	handleFilterState(value: FormationAdministrativeStatus) {
		if (value) {
			this.searchParams.props.administrative_status = value;
		} else {
			delete this.searchParams.props.administrative_status;
		}
		this.searchParams.next();
	}
}
