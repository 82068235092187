<div class="table__container">
	<div class="table__wrapper">
		<table>
			<thead>
				<tr>
					<th>
						{{ 'table_head-formation-date' | translate }}
					</th>
					<th>
						{{ 'formation-session-status' | translate }}
					</th>
					<th>
						{{ 'formation-session-interventions' | translate }}
					</th>
					<th>
						{{ 'formation-session-actions' | translate }}
					</th>
				</tr>
			</thead>
			<tbody>
				<ng-container
					*ngIf="
						formationSessionsTransformed &&
							formationSessionsTransformed.length > 0;
						else noSessionsTpl
					"
				>
					<tr
						*ngFor="
							let formationSession of formationSessionsTransformed;
							let i = index
						"
						class="clicable"
						[routerLink]="[
							'/formations',
							formationId,
							'session',
							formationSession.sessionId,
							'details'
						]"
					>
						<td class="date">
							<span>
								{{
									formationSession.start_date
										? (formationSession.start_date
										  | date
												: 'dd MMMM yyyy'
												: ''
												: translateService.currentLang)
										: ' - '
								}}
							</span>
							<span>
								{{
									formationSession.end_date
										? (formationSession.end_date
										  | date
												: 'dd MMMM yyyy'
												: ''
												: translateService.currentLang)
										: ' - '
								}}
							</span>
						</td>

						<td class="state" [ngSwitch]="formationSession.status">
							<ng-container *ngSwitchCase="'created'">
								<span
									class="d-flex align-items-center justify-content-center"
								>
									<i
										nz-icon
										nzType="exclamation-circle"
										nzTheme="fill"
										class="mr-2"
										[style.color]="'#EA5823'"
									></i>
									<span>{{
										'formation-state-admin-created'
											| translate
									}}</span>
								</span>
							</ng-container>
							<ng-container *ngSwitchCase="'todo'">
								<span>{{
									'formation-state-admin-todo' | translate
								}}</span>
							</ng-container>
							<ng-container *ngSwitchCase="'finished'">
								<span
									class="d-flex align-items-center justify-content-center"
								>
									<i
										nz-icon
										nzType="check-circle"
										class="mr-2"
										[style.color]="'black'"
									></i>
									<span>{{
										'formation-state-admin-finished'
											| translate
									}}</span>
								</span>
							</ng-container>
						</td>
						<td class="interventions">
							{{ formationSession.interventionsCount }}
						</td>
						<td class="actions">
							<a
								[routerLink]="[
									'/formations',
									formationId,
									'session',
									formationSession.sessionId,
									'details'
								]"
								class="btn btn-primary"
							>
								{{
									'formation-session-view-details' | translate
								}}
							</a>
						</td>
					</tr>
				</ng-container>

				<ng-template #noSessionsTpl>
					<tr>
						<td colspan="5">
							{{ 'formation-session-list-empty' | translate }}
						</td>
					</tr>
				</ng-template>
			</tbody>
		</table>
	</div>
</div>
